import React from "react";
import patron from '../components/patron.json';
import testers from './testers.json';
import Link from "react-router-dom/Link";
import { Helmet } from 'react-helmet';       
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Header from "./Header.js";

class Donate extends React.Component {

    state = {
        dialog: false,
        betaDialog: false
    }

    openDialog = () => {
        this.setState({dialog: true});
    } 

    closeDialog = () => {
        this.setState({dialog: false});
    }    
    openBetaDialog = () => {
        this.setState({betaDialog: true});
    } 

    closeBetaDialog = () => {
        this.setState({betaDialog: false});
    }    

    getPatronArray = (i) => {
        let pArr = [];
        pArr = patron[i].arr.map((p, index) => {
            return (
                    <span key={index} className="donate_patron_container">
                    <img className="donate_patron_img" src={p.image} />
                    <a href={p.profile} target="_blank" className="donate_patron_name donate_text_center">{p.name}</a>
                    </span>
            );
        });
    
        return pArr;
    };

    getBetaArray = () => {
        let tArr = [];
        const colors = ["#f44336", "#e91e63", "#9c27b0", "#3f51b5", "#2196f3", "#009688", "#4caf50", "#ff5722", "#ff9800"];
        
        tArr = testers.testers.map((p, index) => {
            const {name, about, img, social} = p;
            const hasImg = img !== "";
            const backgroundColor = colors[Math.floor(Math.random() * colors.length)];
            return (
                    <span key={index} className={"donate_patron_container" + (social ? " tester-hover" : "")} onClick={() => {
                        window.open(social, "_blank");
                    }}>
                    {hasImg ? 
                        <Avatar className="donate_patron_img" src={img}/> : 
                        <Avatar 
                        style={{
                            backgroundColor
                        }}
                        className="donate_patron_img">{name[0]}</Avatar>}
                    
                    {/*<img className="donate_patron_img" src={p.image} />*/}
                    <div className="donate_patron_tester">
                        <p className="donate_patron_name donate_text_center">{name}</p>
                        <p className="donate_patron_name donate_text_center">{about}</p>
                    </div>
                    </span>
            );
        });
    
        return tArr;
    }
    
    getPatronsJSX = (arr, c=4) => {
        const result = [];
        let count = 0;
        while (count < arr.length) {
            let endSlice = count + c;
            if (endSlice > arr.length) {
                endSlice = arr.length;
            }

            const jsx = (
                <div className="donate_patrons">
                    {arr.slice(count, endSlice)}
                </div>
            );
            result.push(jsx);
            count += c;
        }

        return result;
    }

    render() {
        //Doesn't work as expected -> const mail = "https://mail.google.com/mail/?extsrc=mailto&url=" + encodeURIComponent("mailto:contactus@technity.net?subject=I can't see my name on the Patrons List.&body=Please these details so that we can serve you better\nName:\nPatreon Profile:\nMessage:\n");
        return (
            <div>
                <Helmet>
                    <title>Support Us</title>
                    <meta name="theme-color" content="#141e30"/>
                </Helmet>

                
                <Dialog
                open={this.state.dialog}
                onClose={this.closeDialog}
                >
                    <DialogTitle>Don't see your name?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            We update the Patron List on the first day of every month (in some cases the second or third day), to ensure the authenticity of patrons.
                            In case you still don't see your name after a month, you may write to us at <a href={"mailto:contactus@technity.net?subject=" + encodeURIComponent("I can't see my name on the Patrons List.") + "&body=" + encodeURIComponent("Please these details so that we can serve you better\nName:\nPatreon Profile:\nMessage:\n")} target="_blank">contactus@technity.net</a>.
                            Make sure you include appropriate information like your name and Patreon Profile link.
                        </DialogContentText>
                        <DialogActions>
                            <Button onClick={this.closeDialog} color="primary">Okay</Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                <Dialog
                open={this.state.betaDialog}
                onClose={this.closeBetaDialog}
                >
                    <DialogTitle>Don't see your name?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            If you are an active member of the Technity Beta Program and you don't see your name, you may write to us at <a href={"mailto:contactus@technity.net?subject=" + encodeURIComponent("I can't see my name on the Beta testers List.") + "&body=" + encodeURIComponent("Please these details so that we can serve you better\nName:\nMobile:\nMessage:\n")} target="_blank">contactus@technity.net</a>.
                            Make sure you include appropriate information like your name, email and mobile number.
                        </DialogContentText>
                        <DialogActions>
                            <Button onClick={this.closeBetaDialog} color="primary">Okay</Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>

            <div className="donate_main">
            <Header transparent={true}/>
                <div className="container">
                    <h1 className="donate_h1">Support Us.</h1>
                    <p className="donate_subtitle">We create apps for the future. We always believe in providing excellent apps and services to people for free. Ads don't look nice, since we make beautiful apps ;)</p>
                    <p className="donate_subtitle">Support us so that we can continue making such great apps like TxtPolls.</p>
                    <div className="donate_patron_container">
                            <h3 className="donate_h3 donate_text_center">Become a Patron</h3>
                            <p className="donate_subtitle">Support us on Patreon and enjoy Patron Only Benefits from Technity!</p>
                            <Button target="_blank" href="https://www.patreon.com/bePatron?u=14326837" variant="contained" className="donate_patron_button">
                                <img className="donate_patron_button_img" src="/img/patreon.png" />
                                Become a Patron
                            </Button>
                    </div>
                    <div className="donate_patron_container">
                        <h3 className="donate_h3 donate_text_center">One Time Donation</h3>
                        <p className="donate_subtitle">Support us by donating any amount that you like by any of these following methods.</p>
                        <Button target="_blank" href="https://www.paypal.me/technity" variant="contained" className="donate_patron_button">
                            <img className="donate_patron_button_img" src="/img/paypal.png" />
                            Donate with PayPal
                        </Button>
                    </div>
    
                </div>
                <div className="container">
                    <h3 className="donate_h3">Our Backers</h3>
                    <p className="donate_subtitle">Updated Every Month (last updated on November, 2018)</p>
                    {this.getPatronArray(0).length > 0 && <div>
                        <h4 className="donate_h4 donate_text_center">$99 Tier</h4>
                        <p className="donate_subtitle donate_text_center">Cool Rich Internet Person</p>
                        {this.getPatronsJSX(this.getPatronArray(0))}
                    </div>}
                    {this.getPatronArray(1).length > 0 && <div>
                        <h4 className="donate_h4 donate_text_center">$5 Tier</h4>
                        <p className="donate_subtitle donate_text_center">Be a part of the Community</p>
                        {this.getPatronsJSX(this.getPatronArray(1))}
                    </div>}
                    {this.getPatronArray(2).length > 0 && <div>
                        <h4 className="donate_h4 donate_text_center">$1 Tier</h4>
                        <p className="donate_subtitle donate_text_center">Cheaper Than Your Starbucks Coffee</p>
                        {this.getPatronsJSX(this.getPatronArray(2))}
                    </div>}
                    {(this.getPatronArray(2).length <= 0) && <div>
                        <p className="donate_subtitle donate_text_center" style={{paddingTop: 40}}>Currently we don't have any backers on Patreon. Become a Patron now to get featured on this section</p>  
                    </div>}
                    <div className="donate_patron_container">
                            <Button onClick={this.openDialog} variant="contained" className="donate_patron_button">
                                Don't See your name?
                            </Button>
                    </div>  
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className="container">
                    <h3 className="donate_h3">Our Early Access Team</h3>
                    <p className="donate_subtitle">Our awesome team of beta testers</p>
                    <div>
                        {this.getPatronsJSX(this.getBetaArray(), 1)}
                    </div>
                    <div className="donate_patron_container">
                            <Button onClick={this.openBetaDialog} variant="contained" className="donate_patron_button">
                                Don't See your name?
                            </Button>
                    </div>  
                </div>
                <br />
                <br />
                <br />
                <br />
            </div>
            </div>
        );  
    }
}

export default Donate;