import React from "react";
import { connect } from "react-redux";
import { startCheckVoted, setSelectedPoll } from "../actions/poll";
import { CSSTransition } from "react-transition-group";
import Cookies from "universal-cookie";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import CheckIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Ad from "./Ad";

const styles = {
  progress: {
    color: "#FFF",
  },
  homeBtn: {
    background: "#FE6B8B",
    background: " -webkit-linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: "40px",
    border: 0,
    marginLeft: 20,
    color: "white",
    height: 28,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  resultBtn: {
    background: "#f9d423",
    background: " -webkit-linear-gradient(to right, #00c3ff, #ffff1c)",
    background: "linear-gradient(to right, #00c3ff, #ffff1c)",
    borderRadius: "40px",
    border: 0,
    color: "white",
    height: 28,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(11, 252, 30, 0.44)",
  },
};

const cookie = new Cookies();

class VoteOption extends React.Component {
  params = "";
  state = {
    loaded: false,
    poll: {},
  };

  constructor(props) {
    super(props);
    this.params = props.match.params;
  }

  componentWillUnmount() {
    document.body.style = "";
    this.props.dispatch(setSelectedPoll({}, 500));
  }

  componentDidMount() {
    this.props
      .dispatch(
        startCheckVoted(this.params.uid, this.params.pollid, this.params.opt)
      )
      .then()
      .then()
      .then()
      .then((e) => {
        this.setState(() => ({
          loaded: true,
        }));
      })
      .catch((e) => ("Error.", e));
    // if (!voted.includes(this.params.pollid)) {

    // } else {
    //     this.setState(() => ({
    //         hasAlreadyVoted: true
    //     }));
    // }
  }
  render() {
    document.body.style =
      "background: linear-gradient(-20deg, #b721ff 0%, #21d4fd 100%); color: white";
    const voteProcessed = this.props.poll;
    const voted = cookie.get("voted") ? cookie.get("voted") : [];
    // voted.push(this.params.pollid);
    // cookie.set("voted", JSON.stringify(voted), {path: "/", maxAge: 2147483647});
    return (
      <div className="mainVote">
        <Helmet>
          <title>
            Voting {voteProcessed ? " For :" + this.props.title : ""}
          </title>
          <meta name="theme-color" content="#21d4fd" />
          <meta
            name="twitter:title"
            content={
              "Voting" + voteProcessed ? " For :" + this.props.title : ""
            }
          />
          <meta
            name="og:title"
            content={
              "Voting" + voteProcessed ? " For :" + this.props.title : ""
            }
          />
        </Helmet>
        {(this.props.hasAlreadyVoted, this.state.loaded)}
        <CSSTransition
          in={true}
          key={"12132323"}
          timeout={400}
          classNames="fade_page"
        >
          <div className="container">
            <h1 className="voteTitle">Vote</h1>
            <Ad />
            {!this.props.hasAlreadyVoted &&
              this.props.poll &&
              this.props.loaded &&
              !this.props.hasEnded && (
                <VotingSuccess
                  option={this.props.optionName}
                  pollid={this.params.pollid}
                  title={this.props.title}
                />
              )}
            {!this.props.loaded &&
              !this.props.hasAlreadyVoted &&
              !this.props.error && (
                <div className="PollLoading">
                  <CircularProgress
                    className={this.props.classes.progress}
                    size={100}
                    thickness={7}
                  />
                </div>
              )}
            {!!this.props.error && <VotingError />}
            {(this.props.hasAlreadyVoted || this.props.hasEnded) && (
              <AlreadyVoted
                hasEnded={this.props.hasEnded}
                pollid={this.params.pollid}
              />
            )}
            {(this.props.loaded || this.props.hasAlreadyVoted) && (
              <div className="container">
                <div className="columns is-mobile btnContainer">
                  <div className="column is-6 vColumn">
                    <Button
                      to="/"
                      component={Link}
                      className={this.props.classes.homeBtn}
                    >
                      GO HOME
                    </Button>
                  </div>
                  {!this.props.error && (
                    <div className="column is-6 vColumn">
                      <Button
                        to={`/result/${this.params.uid}/${this.params.pollid}`}
                        component={Link}
                        className={this.props.classes.resultBtn}
                      >
                        View Poll Results
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </CSSTransition>
      </div>
    );
  }
}

const AlreadyVoted = (props) => (
  <div className="container">
    <Card className="voteCard">
      <CardContent>
        <WarningIcon className="voteWarning scale-up-center" />
        <h1 className="voteSubtitle">
          {props.hasEnded
            ? "Uh Oh! This Poll has ended."
            : "Sorry! You have voted for this poll already."}
        </h1>
        <h3 className="voteOption">
          {props.hasEnded
            ? "The admin has ended this poll or it has expired."
            : "You can only vote once for a particular poll."}
        </h3>
        <p className="voteID">Poll ID:{props.pollid}</p>
      </CardContent>
    </Card>
  </div>
);

const VotingError = () => (
  <div className="container">
    <Card className="voteCard">
      <CardContent>
        <ErrorIcon className="voteError scale-up-center" />
        <h1 className="voteSubtitle">Oh! Snap! There was an error.</h1>
        <h3 className="voteOption">
          The poll probably doesn't exist or you may have entered the wrong url.
        </h3>
      </CardContent>
    </Card>
  </div>
);

const VotingSuccess = (props) => (
  <div className="container">
    <Card className="voteCard">
      <CardContent>
        <CheckIcon className="voteSuccess scale-up-center" />
        <h1 className="voteSubtitle">
          Thank you for voting in the poll, "{props.title}"
        </h1>
        <h3 className="voteOption">
          You have voted for the option, "{props.option}"
        </h3>
        <p className="voteID">Poll ID:{props.pollid}</p>
      </CardContent>
    </Card>
  </div>
);

//{this.props.poll && <p>{(this.props.poll)}Count: {this.props.poll.options[props.match.opt].vote}</p>}

const mapStateToProps = (state) => {
  if (!state.selectedPoll.error) {
    return {
      hasEnded: state.selectedPoll.hasEnded,
      poll: state.selectedPoll.success,
      loaded: !!state.selectedPoll.loaded,
      hasEnd: !!state.selectedPoll.ended,
      hasAlreadyVoted: state.selectedPoll.hasVoted,
      optionName: state.selectedPoll.optionName,
      title: state.selectedPoll.title,
    };
  } else {
    return {
      error: true,
    };
  }
};

export default withStyles(styles)(connect(mapStateToProps)(VoteOption));
