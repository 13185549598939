import database from "../firebase/firebase";
import axios from "axios";
import { history } from "./../routers/AppRouter";

//TODO: HANDLE ALL .catch() !imp
export const addPoll = (poll) => {
  return {
    type: "ADD_POLL",
    poll,
  };
};

export const setSelectedPoll = (val, code) => {
  switch (code) {
    case 200:
      return {
        type: "VOTED",
        title: val.title,
        optionName: val.optionName,
        hasEnded: val.hasEnded,
        hasVoted: false,
      };
    case 400:
      return {
        type: "ALREADY_VOTED",
        hasEnded: val.hasEnded,
        title: val.title,
        optionName: val.optionName,
      };
    case 404:
      return {
        type: "FAILED_VOTE",
      };
    case 500:
      return {
        type: "RELOAD",
      };
    default:
      return {
        type: "FAILED_VOTE",
      };
  }
};

export const startGetShortUrls = (poll) => {};

export const setResultPoll = (poll) => {
  return {
    type: "RESULT_POLL",
    poll,
  };
};

export const setDetailsPoll = (poll) => {
  return {
    type: "DETAILS_POLL",
    poll,
  };
};

export const hasNoPolls = () => ({
  type: "HAS_NO_POLLS",
});

export const startGetResults = (uid, id) => {
  return (dispatch, getState) => {
    const mainRef = uid
      ? `users/${uid}/polls/${id}`
      : `users/anonymous/polls/${id}`;
    return database
      .ref(mainRef)
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.val();
          dispatch(setResultPoll(snapshot.val()));
        }
      });
  };
};

export const startGetDetails = (uid, id) => {
  return (dispatch, getState) => {
    const mainRef = uid ? `users/${uid}/polls/${id}` : `anonymous/polls/${id}`;
    return database
      .ref(mainRef)
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          dispatch(setDetailsPoll(snapshot.val()));
        }
      });
  };
};

export const startCheckVoted = (uid, id, opt) => {
  return (dispatch, getState) => {
    return axios.get("https://api.ipify.org/?format=json").then((res) => {
      const ip = res.data.ip;
      const mainRef = uid
        ? `users/${uid}/polls/${id}/voters`
        : `anonymous/poll/${id}/voters`;
      return database
        .ref(mainRef)
        .once("value")
        .then((voters) => {
          voters.exists();
          let alreadyVoted = false;
          if (voters.exists()) {
            voters.forEach((child) => {
              if (child.val() === ip) {
                alreadyVoted = true;
              }
            });
            if (alreadyVoted) {
              return dispatch(startGetPoll(uid, id, opt, false));
            } else {
              return database
                .ref(mainRef)
                .push(res.data.ip)
                .then(() => {
                  dispatch(startGetPoll(uid, id, opt, true));
                });
            }
          } else {
            return database
              .ref(mainRef)
              .push(res.data.ip)
              .then(() => {
                dispatch(startGetPoll(uid, id, opt, true));
              });
          }

          // if(poll.exists()) {
          //     const voters = poll.val().voters;
          //     ("voters", voters);
          //     voters.forEach((child) => {
          //         ("child", child);
          //     });
          //     if(voters && (voters.length > 0)) {
          //         ("voters", voters);
          //         if (!(voters.indexOf(res.data.ip) > -1)) {
          //             database.ref(mainRef + "/voters").push(res.data.ip).then(() => {
          //                 dispatch(startGetPoll(uid, id, opt));
          //             });
          //         } else {
          //             dispatch(setSelectedPoll(false));
          //         }
          //     } else {
          //         database.ref(mainRef + "/voters").push(res.data.ip).then(() => {
          //             dispatch(startGetPoll(uid, id, opt));
          //         });
          //     }
          // }
        });
    });
  };
};

export const startGetPoll = (uid, id, opt, n) => {
  return (dispatch, getState) => {
    const mainRef = uid ? `users/${uid}/polls` : "anonymous/polls";
    const ref = mainRef + `/${id}/options/${opt}`;
    let votes = 0;
    return database
      .ref(ref)
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.val();
          const optionName = snapshot.val().option;
          votes = snapshot.val().votes + 1;
          const titleRef = mainRef + `/${id}`;
          database
            .ref(titleRef)
            .once("value")
            .then((snapshot) => {
              if (snapshot.exists()) {
                const hasEnded = snapshot.val().hasEnded;
                const val = {
                  optionName,
                  title: snapshot.val().title,
                  hasEnded,
                  voted: true,
                };

                if (n && !hasEnded) {
                  if (uid !== "anonymous") {
                    sendNotification(uid, snapshot.val().title, id);
                  }
                  database
                    .ref(ref + "/votes")
                    .set(votes)
                    .then(() => dispatch(setSelectedPoll(val, 200)));
                } else {
                  dispatch(setSelectedPoll(val, 400));
                }
              }
            })
            .catch((e) => e);
        } else {
          dispatch(setSelectedPoll(false, 404));
        }
      })
      .catch((e) => {
        dispatch(setSelectedPoll(false, 404));
      });
  };
};

export const getAllUserPolls = (polls) => ({
  type: "POLLS_FETCHED",
  polls,
});

export const startGetAllUserPolls = (uid) => {
  return (dispatch, getState) => {
    const mainRef = `users/${uid}/polls`;
    return database
      .ref(mainRef)
      .once("value")
      .then((snapshot) => {
        let polls = [];
        snapshot.forEach((childSnapshot) => {
          const opt = childSnapshot.val();
          let totalVotes = 0;
          let totalOptions = opt.options.length;
          opt.options.forEach((o) => {
            totalVotes += o.votes;
          });
          const finalOpt = {
            id: childSnapshot.key,
            totalVotes,
            totalOptions,
            ...opt,
          };
          polls.push(finalOpt);
        });
        if (polls.length > 0) dispatch(getAllUserPolls(polls));
        else dispatch(hasNoPolls());
      });
  };
};

export const startAddPoll = (poll) => {
  return (dispatch, getState) => {
    let uid = getState().auth.uid;
    uid = uid ? uid : "anonymous";
    const ref = uid ? `users/${uid}/polls` : "anonymous/polls";
    return database
      .ref(ref)
      .push(poll)
      .then((ref) => {
        const opts = {
          uid,
          id: ref.key,
          ...poll,
        };
        dispatch(addPoll(opts));
        history.push(`/details/${uid}/${ref.key}`);
        getState();
        // database.ref(ref).once("value").then((snapshot) => {
        //     snapshot.forEach((opt) => {
        //         (opt.val());
        //     });
        // });
      });
  };
};

export const startEndPoll = (uid, pollid) => {
  return (dispatch) => {
    const ref = `users/${uid}/polls/${pollid}`;
    return database
      .ref(ref)
      .update({
        hasEnded: true,
      })
      .then(() => {
        ("ENDED");
      });
  };
};

const sendNotification = (uid, title, pollid) => {
  database
    .ref(`/users/${uid}/notificationToken/`)
    .once("value")
    .then((snapshot) => {
      snapshot.forEach((childSnap) => {
        const token = childSnap.key;
        const headers = {
          "Content-Type": "application/json",
          Authorization: "key=AIzaSyARs_TY1teblrX4B6EIOW6sOgKPIWg3_6Q",
        };
        const data = {
          notification: {
            body: "New Vote On Your Poll!",
            title: `New vote on the poll, ${title}`,
            icon: "/favicon.png",
            click_action: `https://txtpolls.web.app/result/${uid}/${pollid}/`,
            absolute: `/result/${uid}/${pollid}/`,
          },
          webpush: {
            fcm_options: {
              link: `https://txtpolls.web.app/result/${uid}/${pollid}/`,
            },
          },
          to: token,
        };
        axios
          .post("https://fcm.googleapis.com/fcm/send", data, {
            headers: headers,
          })
          .then((res) => {})
          .catch((e) => {});
      });
    });
};
