import React from 'react';
import { connect } from 'react-redux';
import { startGetAllUserPolls } from '../actions/poll';
import { Link } from 'react-router-dom';
import moment from 'moment';
import AOS from 'aos';
import CountUp from 'react-countup';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Header from './Header';
import { Helmet } from 'react-helmet';       
import { history } from '../routers/AppRouter';
import AddOption from './AddOption';

const styles = {
    card: {
        margin: 20,
        overflowX: 'auto',
    },
    yT: {
        fontSize: "5rem",
        fontWeight: 800,
        margin: "20px"
    },
    
    pColumn: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 20

    },
    "@media (max-width: 767px)": {
        pColumn: {
            display: 'inherit',
            alignItems: 'none',
            marginRight: 20
    
        },
    },
    loaderContainer: {
        width: '100%',
        marginTop: '20%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    sC: {
        margin: 20
    },
    pollCard: {
        
        margin: 20
    },
    tP: {
        fontFamily: "Product Sans !important",
        fontSize: "4rem",
        display: 'block',
        margin: "20px 0"
    },
    pT: {
        fontFamily: "Product Sans !important",
        fontSize: "4rem",
        fontWeight: "800",
        display: 'inline',
        margin: "20px 0"
    },
    dT: {
        fontSize: "6.5rem",
        fontWeight: 800,
        margin: "20px"
    },
    pP: {
        fontFamily: "Product Sans !important",
        fontSize: "2rem",
        margin: "20px 0"
    },
    tSpan: {
        display: "flex",
        alignItems: "center"
    },
    aC: {
        margin: "20px 10px",
        color: "#fff",
        background: "linear-gradient(to right, #11998e, #38ef7d);"
    },
    eC: {
        margin: "20px 10px",
        color: "#fff",
        background: "linear-gradient(to right, #ff512f, #dd2476);"
    }
};

class Dashboard extends React.Component {

    SORT_BY_DATE_LATEST = 101;
    SORT_BY_DATE_OLDEST = 100;
    SORT_BY_DATE_VOTE_HIGH = 102;
    SORT_BY_DATE_VOTE_LOW = 103;
    
    options = ["First Created", "Last Created", "Votes - High to Low", "Votes - Low to High"];
    
    state = {
        loaded: false,
        anchor: null,
        polls: [],
        votes: 0,
        selected: 0,
        sortBy: this.SORT_BY_DATE_OLDEST,
        emptyMessage: "",
        loading: true
    }

    constructor(props) {
        super(props);
    }

    
    voteCount = 0;

    updateVotes = (p) => {
        let v = 0;
        p.polls.forEach((poll) => {
            v += poll.totalVotes;
        });

        this.setState({
            votes: v
        });
    }

    componentDidMount () {
        this.props.dispatch(startGetAllUserPolls(this.props.user.uid));
        this.props.polls.forEach(poll => {
            this.voteCount += poll.totalVotes;
        });
        this.setState({
            polls: this.props.polls
        });
    }

    componentWillReceiveProps(nextProps) { 
        if (nextProps.polls.length > this.props.polls.length) {
            this.setState({
                polls: nextProps.polls
            });
            this.updateVotes(nextProps);
        }
    }


    

    sortByVotes = (polls) => {
        return polls.sort((p1, p2) => {
            return p1.totalVotes - p2.totalVotes;
        });

    }

    sortByTime = (polls) => {
        return polls.sort((p1, p2) => {
            return p2.timeAdded - p1.timeAdded;
        });
    }

    handleSortByClick = (event) => {
        this.setState({
            anchor: event.currentTarget
        });
        
    }

    handleCloseSortBy = () => {
        this.setState({
            anchor: null
        });
    }

    handleSortByItemClick = (event, index) => {
        const card0 = document.getElementById("card-0");
        const card1 = document.getElementById("card-1");
        const card2 = document.getElementById("card-2");
        if (card0) card0.style.opacity = 0; //TODO: Please! Please find a better way to do this.
        if (card1) card1.style.opacity = 0;
        if (card2) card2.style.opacity = 0;
        
        setTimeout(()=> {
            let sort = this.SORT_BY_DATE_OLDEST;
            let polls = [];
            switch(index) {
                case 0:
                    sort = this.SORT_BY_DATE_OLDEST;
                    polls = this.sortByTime(this.props.polls).reverse();
                    break;
                case 1:
                    sort = this.SORT_BY_DATE_LATEST;
                    polls = this.sortByTime(this.props.polls);
                    break;
                case 2:
                    sort = this.SORT_BY_DATE_VOTE_HIGH;
                    polls = this.sortByVotes(this.props.polls).reverse();
                    break;
                case 3:
                    sort = this.SORT_BY_DATE_VOTE_LOW;
                    polls = this.sortByVotes(this.props.polls);
                    break;
                default:
                    sort = this.SORT_BY_DATE_OLDEST;
                    polls = this.sortByTime(this.state.polls);
            }
            AOS.refreshHard();
            this.setState({
                selected: index,
                anchor: null,
                polls,
                sortBy: sort
            });
            if (card0) card0.style.opacity = 1;
            if (card1) card1.style.opacity = 1;
            if (card2) card2.style.opacity = 1;
        }, 400);
        //
    }

    render() {
        const {classes} = this.props;
        return (
            <div>
            <Helmet>
                <title>Dashboard</title>
                <meta name="theme-color" content="#0072ff"/>
            </Helmet>
            <Header />
            <div className="container">
                <h1 data-aos="fade-up" className={classes.dT}>Dashboard</h1>
                {(this.props.polls.length <= 0 && !this.props.hasNoPolls) && <div className={classes.loaderContainer}>
                    <CircularProgress size={100} thickness={5} className={classes.loader} color="primary"/>
                </div>}
                {this.props.hasNoPolls && <div className="dashboard-no-polls">
                    <img src="/img/empty.svg" className="dashboard-no-img" />
                    <p>You have not created any polls. <Link to={"/"}>Go ahead and create one!</Link></p>    
                </div>}
                {this.props.polls.length > 0 &&
                <div>
                    <Card data-aos="fade-up" className={classes.pollCard}>
                        <CardContent>
                            <div className="columns is-mobile">
                                <div className="column is-6">
                                <h1 className={classes.pT}>Total Polls</h1>
                                <CountUp className={classes.tP} end={this.state.polls.length} start={0} delay={0} duration={4} />
                                </div>
                                <div className="column is-6">
                                <h1 className={classes.pT}>Total Votes</h1>
                                <CountUp className={classes.tP} end={this.state.votes} start={0} delay={1} duration={5} />
                                </div>
                            </div>
                            
                        </CardContent>
                    </Card>
                <div data-aos="fade-up" className={classes.pColumn + " columns"}>

                    <div className="column is-three-quarters">
                        <h1  className={classes.yT}>Your Polls</h1>
                    </div>

                    <div className={classes.sC + " column"}>
                        <List component="nav">
                            <ListItem
                            button
                            aria-haspopup="true"
                            aria-controls="sort-menu"
                            aria-label="sort by"
                            onClick={this.handleSortByClick}
                            >
                            <ListItemText
                                primary="Sort By"
                                secondary={this.options[this.state.selected]}
                            />
                            </ListItem>
                        </List>

                        <Menu
                        id="sort-menu"
                        anchorEl={this.state.anchor}
                        open={Boolean(this.state.anchor)}
                        onClose={this.handleCloseSortBy}
                        >
                            {this.options.map((option, index) => (
                                <MenuItem
                                key={option}
                                selected={index === this.state.selected}
                                onClick={event => this.handleSortByItemClick(event, index)}
                                >
                                {option}
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                </div>
            </div>}
            
            {this.state.polls.length > 0 && this.props.polls.map((poll, index) => {
                const today = moment().startOf('day');
                let dateString = "";
                const pollCreated = moment(poll.timeAdded).startOf('day');
                const diff = today.diff(pollCreated, 'days');
                if(diff == 0) {
                    dateString = "Today";
                } else if(diff == 1){
                    dateString = "Yesterday";
                } else {
                    dateString = pollCreated.format("dddd, MMMM Do, YYYY");
                }
                return (
                    <Card data-aos="fade-up" key={index} id={"card-"+ index} className={classes.pollCard}>
                        <CardActionArea style={{width: "100%"}}>
                            <CardContent>
                                <span className={classes.tSpan}><h1 className={classes.pT}>{poll.title}</h1><Chip className={poll.hasEnded ? classes.eC : classes.aC} label={!poll.hasEnded ? "Active" : "Ended"} /></span>
                                <p className={classes.pP}>Created {(dateString == "Today" || dateString == "Yesterday") ? "" : "on"} {dateString} </p>
                                <p className={classes.pP}>{poll.totalOptions} Options</p>
                                <p className={classes.pP}>{poll.totalVotes != 0 ? poll.totalVotes : "No"} vote{poll.totalVotes != 1 ? "s" : ""} till now.</p> 
                                <p className={classes.pP}></p>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button component={Link} to={`/details/${this.props.user.uid}/${poll.id}`} size="small" color="primary">
                                DETAILS
                            </Button>
                            <Button size="small" component={Link} to={`/result/${this.props.user.uid}/${poll.id}`} color="primary">
                                RESULTS
                            </Button>
                        </CardActions>
                    </Card>
                );
                })
            }
            </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        polls: state.userPolls,
        user: state.auth,
        hasNoPolls: state.poll.hasNoPolls ? state.poll.hasNoPolls : false
    };
};

export default withStyles(styles)(connect(mapStateToProps)(Dashboard));