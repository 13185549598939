import {firebase, googleAuthProvider, faceBookAuthProvider, twitterAuthProvider} from './../firebase/firebase';
export const startLogin = (provider) => {
    return () => {
        switch(provider) {
            case 'g':
                return firebase.auth().signInWithPopup(googleAuthProvider);
            case 't':
                return firebase.auth().signInWithPopup(twitterAuthProvider);
            case 'f':
                return firebase.auth().signInWithPopup(faceBookAuthProvider);
        }
        
    };
};

export const login = (userInfo) => {
    return {
        type: "LOGIN",
        userInfo
    };
};

export const logout = () => ({
    type: "LOGOUT"
});

export const notificationReceived = (payload) => ({
    type: "MESSAGE",
    payload
});

export const startLogout = () => {
    return () => {
        return firebase.auth().signOut();
    };
};