import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AppRouter, { history } from "./routers/AppRouter";
import configStore from "./store/config.store";
import { login, logout, notificationReceived } from "./actions/auth";
import "./styles/styles.scss";
import AOS from "aos";
import * as Vibrant from "node-vibrant";
import "aos/dist/aos.css";
import database, { firebase, messaging } from "./firebase/firebase";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";

const config = configStore();
const store = config.store;
const state = store.getState();

let hasRendered = false;
const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(<App />, document.getElementById("app"));
    hasRendered = true;
  }
};

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: "#ffffff",
      main: "#f9f9f9",
      dark: "#c6c6c6",
      contrastText: "#fff",
    },
    primary: {
      light: "#6ec6ff",
      main: "#2196f3",
      dark: "#0069c0",
      contrastText: "#fff",
    },
    google: "#e53935",
  },
  typography: {
    fontFamily: "Product Sans",
    fontSize: "1.5rem",
  },
});

// (store.getState());
class App extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    AOS.init();
  }
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate
            loading={<div>Loading</div>}
            persistor={config.persistor}
          >
            <AppRouter />
          </PersistGate>
        </Provider>
      </MuiThemeProvider>
    );
  }
}
// ReactDOM.render(<p>Loading...</p> , document.getElementById("app"));
renderApp();

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    let auth = {
      uid: user.uid,
      displayName: user.displayName,
      palette: {
        lightVibrant: "#FE6B8B",
        vibrant: " #FF8E53",
      },
      photoURL: user.photoURL ? user.photoURL : undefined, //TODO:ADD PLACEHOLDER HERE
    };
    if (user.photoURL) {
      Vibrant.from(user.photoURL).getPalette((err, palette) => {
        auth.palette = {
          lightVibrant: palette.LightVibrant.getHex(),
          vibrant: palette.Vibrant.getHex(),
        };
        store.dispatch(login(auth));
      });
    } else {
      store.dispatch(login(auth));
    }
    try {
      messaging
        .requestPermission()
        .then(() => {
          //Permission granted
          return messaging.getToken();
        })
        .then((token) => {
          database.ref(`/users/${user.uid}/notificationToken/${token}/`).set(1);
        })
        .catch((e) => {
          messaging.requestPermission();
          console.log("Permission denied. ", e);
        });
    } catch (e) {
      console.log("Notifications not supported");
    }
  } else {
    store.dispatch(logout());
    renderApp();
  }
});

// try {
//   messaging.onMessage((payload) => {
//     //TODO: Handle Notifications when user is using the webapp.
//     store.dispatch(notificationReceived(payload));
//   });

//   messaging.onTokenRefresh(() => {
//     messaging
//       .getToken()
//       .then((token) => {
//         database.ref(`/users/${user.uid}/notificationToken${token}/`).set(1);
//       })
//       .catch((e) => {
//         console.log(e);
//       });
//   });
// } catch (err) {
//   console.log("Notifications Not Supported");
// }
