import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import CoFounder from './CoFounder';


class About extends React.Component {

    render() {
        return(
            <div>
                <Helmet>
                    <title>About Us</title>
                    <meta name="theme-color" content="#0072ff"/>
                </Helmet>
                <Header />
                <div className="container">
                    <div className="about__titleContainer">
                        <div data-aos="zoom-in" className="columns">
                            <div className="column is-vcentered is-6">
                                <h1 className="about__title">We are Technity</h1>
                                <p className="about__subtitle">We Build Apps for the future!</p>
                                <p className="about__intro">Technity started out as a group of 3 high school students wanting to design the best looking icon pack for Android. We still are the same 3 people but we have created some pretty cool stuff along the way except that icon pack :P</p>
                                <p className="about__intro">TxtPolls is one of our best works ever. Many of you may have wanted to create a poll that you can share with your friends on messaging apps like WhatsApp, but the process was tedious. TxtPolls helps you create a text based poll that you can share to your friends through any messaging apps</p>
                            </div>
                            <div className="column is-6">
                                <img className="about__intro--img" style={{height: 400}} src="/img/team.svg" />
                            </div>
                        </div>
                    </div>
                    <div className="about__teamCotainer">
                        <div className="about__team__title">
                            <h1 className="about__title">Our Team</h1>
                        </div>
                        <div className="columns">
                                <div data-aos="zoom-in-up"
                                className="column is-4 about_card">
                                    <CoFounder name="Ajesh DS"
                                    steam="https://steamcommunity.com/id/thekillingspree"
                                    dribbble="https://dribbble.com/thekillingspree"
                                    gplus="https://plus.google.com/+AjeshDSthegr8"
                                    spotify="https://open.spotify.com/user/ajeshd17?si=o0K6no05RTaLuMGFkWlq6A"
                                    twitter="https://twitter.com/thekillingspre3"
                                    github="https://github.com/thekillingspree"
                                    facebook="https://www.facebook.com/thekillingspre3"
                                    insta="https://www.instagram.com/thekillingspree/"
                                    description="Full Stack Developer & Designer"
                                    college="Terna Engineering College" 
                                    imgUrl="img/tks.jpg"
                                    />
                                </div>
                                <div data-aos="zoom-in-up"
                                data-aos-delay="300"
                                className="column is-4 about_card">
                                <CoFounder name="Prateesh RK"
                                dribbble="https://dribbble.com/prateeshrk"
                                snap="https://www.snapchat.com/add/prateeshrk"
                                spotify="https://open.spotify.com/user/prateeshrk?si=5vpjrpnfRT2zficWSNYSww"
                                gplus="https://plus.google.com/+PrateeshRK"
                                twitter="https://twitter.com/iamprateeshrk"
                                github="https://github.com/prateeshrk"
                                insta="https://www.instagram.com/prateesh.rk/"
                                college="Fr. C. Rodrigues Institute of Technology" 
                                description="Full Stack Developer & Designer"
                                imgUrl="img/prk.jpg"
                                />
                                </div>
                                <div data-aos="zoom-in-up"
                                data-aos-delay="600"
                                className="column is-4 about_card">
                                <CoFounder name="Rahul Nair" 
                                twitter="https://twitter.com/rahulnair112000"
                                github="https://github.com/rahulnair1100"
                                gplus="https://plus.google.com/105544470934419139336"
                                insta="https://www.instagram.com/rahulnair1100/"
                                facebook="https://www.facebook.com/rahulnair1100"
                                college="Pillai College of Engineering"
                                description="Full Stack Developer & Designer"
                                imgUrl="img/rn.jpg"
                                />
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;