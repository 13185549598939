export default(state={}, action) => {
    switch(action.type) {
        case "ADD_POLL":
            return action.poll;
        case "RESULT_POLL":
            return action.poll;
        case "DETAILS_POLL":
            return action.poll;
        case 'HAS_NO_POLLS':
            return {...state, hasNoPolls: true}
        default:
            return state;
    }
};