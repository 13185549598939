import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/messaging";

const config = {
  apiKey: "AIzaSyAno_1zllufQ0043SSnf2N31o8ZzfQm_zM",
  authDomain: "txtpolls.firebaseapp.com",
  databaseURL: "https://txtpolls.firebaseio.com",
  projectId: "txtpolls",
  storageBucket: "txtpolls.appspot.com",
  messagingSenderId: "326539624746",
  appId: "1:326539624746:web:90705bc90d8aaf55c19e53",
  measurementId: "G-VKE1ND2EWS",
};

console.log(config);

firebase.initializeApp(config);

const database = firebase.database();
let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(
    "BP9wqIkEo0dG4VdmztAtVu9TfVKzpRBUZwKpvgG7Eg0GqEN8NpUophLzK2VIsrPYs7NgdGiWTQ5txGN5ZPAlFU4"
  );
}
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const faceBookAuthProvider = new firebase.auth.FacebookAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

export {
  firebase,
  messaging,
  faceBookAuthProvider,
  googleAuthProvider,
  twitterAuthProvider,
  database as default,
};
