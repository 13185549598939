import React from 'react'; 
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Button from '@material-ui/core/Button';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Link from 'react-router-dom/Link';
import { Helmet } from 'react-helmet';       
import Header from './Header';


class Faq extends React.Component {

    state = {
        expanded: null
    }

    togglePanel = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false
        });
    }

    render() {
        const {expanded} = this.state;
        return (
            <div className="faq_bg">
            <Helmet>
                <title>FAQ</title>
                <meta name="theme-color" content="#323974"/>
            </Helmet>
                <Header transparent={true}/>
                <div className="container">
                    <h1 className="faq_h1">FAQ</h1>
                    <div className="faq_container">
                        <ExpansionPanel className="faq_panel" expanded={expanded === "p1"} onChange={this.togglePanel('p1')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className="faq_icon"/>}>
                                <p className="faq_title faq_text">What Exactly is TxtPolls?</p>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <p className="faq_answer faq_text">
                                    TxtPolls is a text based poll creation app that lets users create polls that can be shared anywhere, like WhatsApp, Facebook Messenger, Email, or even SMS. 
                                    Poll creation is simple, give your poll a title and add the poll options(minimum 2) and that's it! You'll get a poll link and a template message that you can share anywhere.
                                </p>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel className="faq_panel" expanded={expanded === "p2"} onChange={this.togglePanel('p2')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className="faq_icon"/>}>
                                <p className="faq_title faq_text">Is TxtPolls free?</p>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <p className="faq_answer faq_text">
                                    TxtPolls is absolutely free to use and always will be 😄
                                </p>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel className="faq_panel" expanded={expanded === "p9"} onChange={this.togglePanel('p9')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className="faq_icon"/>}>
                                <p className="faq_title faq_text">I love this app and really find this helpful. How can I support you guys?</p>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <p className="faq_answer faq_text">
                                Visit the ‘Support Us’ page and you can either become a patron or make a one time donation 💗
                                </p>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel className="faq_panel" expanded={expanded === "p3"} onChange={this.togglePanel('p3')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className="faq_icon"/>}>
                                <p className="faq_title faq_text">Can I create polls without logging in?</p>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <p className="faq_answer faq_text">
                                    Yes, you can create polls anonymously, but these polls will be valid only for 15 days, after which they'll be deleted permanently. 
                                </p>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel className="faq_panel" expanded={expanded === "p7"} onChange={this.togglePanel('p7')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className="faq_icon"/>}>
                                <p className="faq_title faq_text">Can I use TxtPolls to ask out my crush?</p>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <p className="faq_answer faq_text">
                                    Yeah, sure! It's a much cooler way to get rejected 😉
                                </p>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel className="faq_panel" expanded={expanded === "p4"} onChange={this.togglePanel('p4')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className="faq_icon"/>}>
                                <p className="faq_title faq_text">Can I create unlimited polls?</p>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <p className="faq_answer faq_text">
                                    Yes, as long as you are logged in, you can create any number of polls you like. 
                                </p>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel className="faq_panel" expanded={expanded === "p8"} onChange={this.togglePanel('p8')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className="faq_icon"/>}>
                                <p className="faq_title faq_text">This is cool but what's your GPA?</p>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <p className="faq_answer faq_text">
                                    GTFO. 
                                </p>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel className="faq_panel" expanded={expanded === "p5"} onChange={this.togglePanel('p5')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className="faq_icon"/>}>
                                <p className="faq_title faq_text">Does TxtPolls run on Internet Explorer?</p>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <p className="faq_answer faq_text">
                                    Idk. Does it? 🤔
                                </p>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel className="faq_panel" expanded={expanded === "p6"} onChange={this.togglePanel('p6')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className="faq_icon"/>}>
                                <p className="faq_title faq_text">Can I use Txtpolls for elections?</p>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <p className="faq_answer faq_text">
                                    No. We don't want the Russians to hack us 🚫
                                </p>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel className="faq_panel" expanded={expanded === "p10"} onChange={this.togglePanel('p10')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className="faq_icon"/>}>
                                <p className="faq_title faq_text">I went through all the FAQs. What should I do now?</p>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <p className="faq_answer faq_text">
                                Subscribe to <a href="https://www.youtube.com/user/PewDiePie" target="_blank" className="welovepewds">PewDiePie</a> if you aren’t subscribed already 😊
                                </p>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <Button variant="contained" component={Link} to="/" className="faq_button">Try TxtPolls</Button>
                    </div>
                </div>
            </div>);
    }

}

export default Faq;