export default(state={}, action) => {
    switch(action.type) {
        case "VOTED":
            return {
                ...state,
                title: action.title,
                optionName: action.optionName,
                hasEnded: action.hasEnded,
                success: true,
                loaded: true,
                hasVoted: action.hasVoted,
                error: false
            };
        case "ALREADY_VOTED": {
            return {
                ...state,
                title: action.title,
                hasEnded: action.hasEnded,
                loaded: true,
                optionName: action.optionName,
                error: false,
                hasVoted: true
            };
        }
        case "FAILED_VOTE": {
           return {
                ...state,
                error: true
           };
        }
        case "RELOAD": {
            return {};
        }
        default:
            return state;
    }
};