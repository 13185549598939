import React from 'react';
import { connect } from 'react-redux'; 
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({
    isAuthenticated, 
    component: Component,
    ...rest
}) => (
    <Route {...rest} component={(props) => (
        !isAuthenticated ? (
            <Component {...props}/>
        ) : (
            <Redirect to={{
                pathname: "/",
                state: {
                    error: true
                }
            }}/>
        )
    )}/>
);

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.auth.uid,
});

export default connect(mapStateToProps)(PublicRoute);