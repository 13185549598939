import React from 'react';
import Button from '@material-ui/core/Button';  
import { withStyles } from '@material-ui/core/styles';
import Link from 'react-router-dom/Link';
import {Helmet} from "react-helmet";

const styles = {
    fBG: {
        height: "101vh",
        background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)"
    },
    main404: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },

    columnContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: "0 30px"
    },
    oops: {
        fontSize: "10rem",
        textShadow: "0px 1px 8px #FFF",
        fontWeight: 900,
        color: "#FFF"
    },
    subtitle: {
        fontSize: "3rem",
        color: "#FFF"
    },
    four: {
        fontSize: "26rem",
        textShadow: "0px 1px 8px #FFF",
        fontWeight: 900,
        color: "#FFF"
    },
    txtP: {
        background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", 
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
    },
    homeBtn: {
        background: "linear-gradient(to right, #fff, #fff)",
        borderRadius: 40,
        border: 0,
        margin: "30px 0px",
        color: 'white',
        height: 48,
        maxWidth: "200px",
        padding: '0 30px',
        fontSize: "1.5rem",
        fontWeight: 900,
        boxShadow: '0px 1px 20px 1px rgba(254, 254, 254, 0.58)',
        '&:hover': {
            boxShadow: '0px 5px 20px 1px rgba(254, 254, 254, 0.51)'
        }
    },
    '@media (max-width: 767px)': {
        oops: {
            fontSize: "7rem",
            fontWeight: 900,
            color: "#FFF"
        },
        subtitle: {
            fontSize: "1.7rem",
            color: "#FFF"
        },
        four: {
            fontSize: "18rem",
            fontWeight: 900,
            color: "#FFF"
        },
        columnContainer: {
            alignItems: 'center',
        }
    },
    '@media (max-width: 321px)': {
        oops: {
            fontSize: "5rem",
            fontWeight: 900,
            color: "#FFF"
        },
        subtitle: {
            fontSize: "1.7rem",
            color: "#FFF"
        },
        four: {
            fontSize: "15rem",
            fontWeight: 900,
            color: "#FFF"
        },
        columnContainer: {
            alignItems: 'center',
        }
    },
    '@media (max-width: 1024px)': {
        columnContainer: {
            alignItems: 'center',
            width: "100%"
        }
    }
    
};

const FourOFour = (props) => {
    const {classes } = props;
    return (
        <div className={classes.fBG}>
            <Helmet>
                <title>You are Lost!</title>
                <meta name="theme-color" content="#fe6b8b" />
            </Helmet>
            <div className="container">
                <div className="columns is-desktop" style={{paddingTop: "20vh"}}>
                    
                    <div className={"column is-6 " + classes.columnContainer}>
                        <h1 className={classes.four}>
                            404
                        </h1>
                    </div>
                    <div className={"column is-6 " + classes.columnContainer}>
                        <h1 className={classes.oops}>
                            Oops!
                        </h1>
                        <h3 className={classes.subtitle}>
                            Looks like you were lost...
                        </h3>
                        <Button component={Link}  to={"/"} className={classes.homeBtn}><p className={classes.txtP}>GO HOME</p></Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withStyles(styles)(FourOFour);