import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import moment from "moment";
import { startGetDetails, startEndPoll } from "../actions/poll";
import CloseIcon from "@material-ui/icons/Close";
import MoreIcon from "@material-ui/icons/MoreVert";
import { Helmet } from "react-helmet";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";

const styles = {
  whatsApp: {
    background: "linear-gradient(45deg, #11998e 30%, #38ef7d 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    margin: "30px 0px",
    height: 28,
    padding: "0 30px",
    boxShadow: "0 3px 9px 2px rgba(26, 227, 67, 0.38)",
    "&:hover": {
      boxShadow: "0 3px 15px 2px rgba(26, 227, 67, 0.38)",
    },
  },
  voteBtn: {
    background: "linear-gradient(to right, #0072ff, #00c6ff)",
    borderRadius: 3,
    border: 0,
    color: "white",
    margin: "30px 0px",
    height: 28,
    padding: "0 30px",
    boxShadow: "0 3px 9px 2px rgba(11, 169, 252, 0.43)",
    "&:hover": {
      boxShadow: "0 3px 15px 2px rgba(11, 169, 252, 0.43)",
    },
  },
  clipBoard: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    width: "100%",
    margin: "30px 0px",
    height: 28,
    padding: "0 30px",
    boxShadow: "0 3px 9px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      boxShadow: "0 3px 15px 2px rgba(255, 105, 135, .3)",
    },
  },
  shareBtn: {
    background: "linear-gradient(to right, #833ab4, #fd1d1d, #fcb045)",
    borderRadius: 3,
    border: 0,
    color: "white",
    width: "100%",
    margin: "30px 0px",
    height: 28,
    padding: "0 30px",
    boxShadow: "0 3px 9px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      boxShadow: "0 3px 15px 2px rgba(255, 105, 135, .3)",
    },
  },
  pollTitle: {
    fontWeight: 900,
    fontSize: "2.4rem",
    marginBottom: "10px",
  },
  pollOptions: {
    fontSize: "1.7rem",
  },
  cardContent: {
    padding: "2.5rem 3rem",
  },
  hide: {
    position: "absolute",
    left: "-9999px",
  },
  "@media (max-width: 767px)": {
    voteBtn: {
      fontSize: "6px",
    },
    whatsApp: {
      fontSize: "8px",
    },
    clipBoard: {
      fontSize: "8px",
    },
    shareBtn: {
      fontSize: "8px",
    },
  },
};

// eslint-disable-next-line no-extend-native
String.prototype.trunc = function (n, useWordBoundary) {
  if (this.length <= n) {
    return this;
  }
  var subString = this.substr(0, n - 1);
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString) + " ..."
  );
};

class Details extends React.Component {
  state = {
    loaded: false,
    dialogOpen: false,
    btnLoading: false,
    hasEnded: false,
    anchor: null,
    detailsLoaded: [],
    result: undefined,
    error: {
      e: undefined,
      msg: "",
    },
  };

  handleClose = () => {
    this.setState({
      error: {
        e: false,
        msg: "",
      },
    });
  };

  handlePollEnd = () => {
    this.setState({
      btnLoading: true,
    });
    this.props
      .dispatch(startEndPoll(this.params.uid, this.params.pollid))
      .then(() => {
        this.setState({
          btnLoading: false,
          hasEnded: true,
        });
        this.handleCloseDialog();
      });
  };

  openDialog = () => {
    this.setState({
      dialogOpen: true,
    });
  };

  handleCloseDialog = () => {
    this.setState({
      dialogOpen: false,
      anchor: null,
    });
  };

  handleCloseMenu = () => {
    this.setState({
      anchor: null,
    });
  };

  handleOpenMenu = (event) => {
    this.setState({
      anchor: event.currentTarget,
    });
  };

  constructor(props) {
    super(props);
    this.params = props.match.params;
  }

  setError = (err) => {
    this.setState(err);
  };

  makeShareTxt = (poll) => {
    //let sharetxt = `*${poll.title}*\n\nClick on the links corresponding to each to option to vote.\n\n`;
    poll.options.forEach((option, index) => {
      axios
        .post(
          "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAno_1zllufQ0043SSnf2N31o8ZzfQm_zM",
          {
            dynamicLinkInfo: {
              dynamicLinkDomain: "txp.page.link",
              link: `https://txtpolls.web.app/vote/${this.params.uid}/${this.params.pollid}/${index}`,
              navigationInfo: {
                enableForcedRedirect: "1",
              },
            },
            suffix: {
              option: "SHORT",
            },
          }
        )
        .then((res) => {
          //sharetxt += `${index + 1}. ${option.option} ${res.data.shortLink}`;
          this.setState((prevState) => ({
            detailsLoaded: prevState.detailsLoaded.concat([
              [option, res.data.shortLink, index],
            ]),
          }));
        });
    });

    axios
      .post(
        "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAno_1zllufQ0043SSnf2N31o8ZzfQm_zM",
        {
          dynamicLinkInfo: {
            dynamicLinkDomain: "txp.page.link",
            link: `https://txtpolls.web.app/result/${this.params.uid}/${this.params.pollid}/`,
            navigationInfo: {
              enableForcedRedirect: "1",
            },
          },
          suffix: {
            option: "SHORT",
          },
        }
      )
      .then((res) => {
        this.setState({ result: res.data.shortLink });
      });

    //sharetxt += `\nView Results https://txtpolls.web.app/result/${this.params.uid}/${this.params.pollid}/\n\n Create your own text based Polls at\n https://txtpolls.web.app/`;
  };

  componentDidMount() {
    document.body.style =
      "background: -webkit-linear-gradient(to right, #ff6a00, #ee0979);  background: linear-gradient(to right, #ff6a00, #ee0979);";
    this.props
      .dispatch(startGetDetails(this.params.uid, this.params.pollid))
      .then((e) => {
        this.setState({ loaded: true });
        if (this.props.poll.title) {
          this.makeShareTxt(this.props.poll);
        }
      });
  }

  componentWillUnmount() {
    document.body.style = "background: #FFF;";
  }
  // in={(this.state.detailsLoaded.length === this.props.poll.options.length) && this.state.result }
  render() {
    return (
      <div>
        <Helmet>
          <title>Poll Details</title>
          <meta name="theme-color" content="#ff6a00" />
        </Helmet>
        <Header transparent={true} />
        <Dialog open={this.state.dialogOpen} onClose={this.handleCloseMenu}>
          <DialogTitle>Are you Sure you want to end this Poll?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ending a poll will disable further voting, but the results will
              still be available. This action is Irreversible.
            </DialogContentText>
            <DialogActions>
              {this.state.btnLoading && (
                <CircularProgress size={24} color="primary" />
              )}
              <Button
                disabled={this.state.btnLoading}
                onClick={this.handlePollEnd}
              >
                END POLL
              </Button>
              <Button onClick={this.handleCloseDialog} color="primary">
                KEEP ACTIVE
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
        {
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={!!this.state.error.e}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={
              <span id="message-id" className="AddOption_errorMessage">
                {this.state.error.msg}
              </span>
            }
            autoHideDuration={5000}
            onClose={this.handleClose}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          ></Snackbar>
        }
        <TransitionGroup className="details_card_anim">
          {this.props.poll.title ? (
            this.state.detailsLoaded.length ===
              this.props.poll.options.length && this.state.result ? (
              <CSSTransition
                key={"zero-one"}
                timeout={400}
                classNames="fade_page"
              >
                <SuccessJSX
                  uid={this.params.uid}
                  openDialog={this.openDialog}
                  hasEnded={this.state.hasEnded}
                  result={this.state.result}
                  detailsLoaded={this.state.detailsLoaded}
                  setError={this.setError}
                  onOpenMenu={this.handleOpenMenu}
                  onCloseMenu={this.handleCloseMenu}
                  classes={this.props.classes}
                  anchor={this.state.anchor}
                  auth={
                    this.params.uid === this.props.authID &&
                    this.params.uid !== "anonymous"
                  }
                  params={this.params}
                  poll={this.props.poll}
                />
              </CSSTransition>
            ) : (
              <CSSTransition
                in={true}
                key={"FAIL-two"}
                timeout={400}
                classNames="fade_page"
              >
                <FailureJSX />
              </CSSTransition>
            )
          ) : (
            <CSSTransition
              in={true}
              key={"FAIL-three"}
              timeout={400}
              classNames="fade_page"
            >
              <FailureJSX />
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    );
  }
}

const getExpiry = (ms) => {
  let diff = 0;
  const created = moment(ms).startOf("day");
  const today = moment().startOf("day");
  const fifteen = created.add(15, "days");
  diff = fifteen.diff(today, "days");
  return diff;
};

const sortFunction = (op1, op2) => {
  return op1[2] - op2[2];
};

const SuccessJSX = (props) => {
  let sharetxt = `*${props.poll.title}*\n\nClick on the links corresponding to each option to vote.\n\n`;
  props.detailsLoaded.sort(sortFunction).forEach((option, index) => {
    const t = `${option[2] + 1}. ${option[0].option} ${option[1]}  \n\n`;
    sharetxt += t;
  });
  sharetxt += `\nView Results ${props.result}\n\n Create your own text based Polls at\n https://txtpolls.web.app/`;
  return (
    <div className="container mainDetailsContainer">
      <Card>
        <CardContent className={props.classes.cardContent}>
          {
            <CardHeader
              title={
                <h2 className={props.classes.pollTitle}>{props.poll.title}</h2>
              }
              subheader={
                props.uid === "anonymous"
                  ? `This poll will end in ${getExpiry(
                      props.poll.timeAdded
                    )} days`
                  : props.poll.hasEnded || props.hasEnded
                  ? "This poll has been ended."
                  : "Active"
              }
              action={
                props.auth && (
                  <div>
                    <IconButton onClick={props.onOpenMenu}>
                      <MoreIcon />
                    </IconButton>
                    <Menu
                      id="poll-options"
                      anchorEl={props.anchor}
                      open={Boolean(props.anchor)}
                      onClose={props.onCloseMenu}
                    >
                      <MenuItem
                        disabled={props.poll.hasEnded}
                        onClick={() => {
                          props.openDialog();
                        }}
                      >
                        End Poll
                      </MenuItem>
                      <MenuItem>Enable Multiple Votes</MenuItem>
                    </Menu>
                  </div>
                )
              }
            />
          }
          <List dense={false}>
            {props.poll.options.map((option, index) => (
              <ListItem key={index}>
                <ListItemText disableTypography>
                  <p>
                    {index + 1}. {option.option.trunc(25, true)}
                  </p>
                </ListItemText>
                <ListItemSecondaryAction>
                  <Button
                    className={props.classes.voteBtn}
                    component={Link}
                    to={`/vote/${props.params.uid}/${props.params.pollid}/${index}`}
                  >
                    Vote
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          <div className="columns is-mobile">
            <div className="column is-3">
              <Button
                fullWidth
                className={props.classes.voteBtn}
                component={Link}
                to={`/result/${props.params.uid}/${props.params.pollid}`}
              >
                Poll Results
              </Button>
            </div>
            <div className="column is-3">
              <textarea
                className={props.classes.hide + " copytextarea"}
                readOnly
                value={sharetxt}
              ></textarea>
              <Button
                fullWidth
                onClick={() => {
                  const copyTextarea = document.querySelector(".copytextarea");
                  copyTextarea.select();
                  try {
                    const successful = document.execCommand("copy");
                    props.setError({
                      error: {
                        e: true,
                        msg: successful
                          ? "Copied To Clipboard! It'll rock 🤘!"
                          : "Oops, unable to copy 😕",
                      },
                    });
                  } catch (err) {
                    props.setError({
                      error: {
                        e: true,
                        msg: "Oops, unable to copy 😕",
                      },
                    });
                  }
                }}
                className={props.classes.clipBoard}
              >
                Copy To Clipboard
              </Button>
            </div>
            <div className="column is-3">
              <Button
                fullWidth
                onClick={() => {
                  if (navigator.share) {
                    navigator
                      .share({
                        title: props.poll.title,
                        text: sharetxt,
                      })
                      .then(() => "Successful share")
                      .catch((error) => console.error("Error sharing", error));
                  } else {
                    props.setError({
                      error: {
                        e: true,
                        msg: "Share feature Unavailable 😕",
                      },
                    });
                  }
                }}
                className={props.classes.shareBtn}
              >
                Share
              </Button>
            </div>
            <div className="column is-3">
              <Button
                fullWidth
                className={props.classes.whatsApp}
                onClick={() => {
                  const url = `https://api.whatsapp.com/send?text=${encodeURI(
                    sharetxt
                  )}`;
                  window.open(url, "_blank").focus();
                }}
              >
                Share on WhatsApp
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

const FailureJSX = (props) => {
  return (
    <div>
      <div className="PollLoading">
        <CircularProgress size={100} style={{ color: "#FFF" }} thickness={7} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    poll: state.poll,
    authID: state.auth.uid,
  };
};

export default withStyles(styles)(connect(mapStateToProps)(Details));
