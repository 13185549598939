import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import KeyDown from "@material-ui/icons/KeyboardArrowDown";
import Link from "react-router-dom/Link";

const styles = (theme) => ({
  root: {
    height: "100vh",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  title: {
    fontSize: "6rem",
    fontWeight: 900,
    color: "#FFF",
  },
  subtitle: {
    fontSize: "2rem",
    color: "#FFF",
  },
  arr: {
    height: "9rem",
    width: "9rem",
  },
  arrContainer: {
    position: "absolute",
    width: "100%",
    top: "80vh",
    justifyContent: "center",
    display: "flex",
    left: 0,
  },
});

const HomeDisplay = (props) => {
  const { classes } = props;
  return (
    <div>
      <p className={"arrow bounce " + classes.arrContainer}>
        <KeyDown className={classes.arr + " arrow_animate"} />
      </p>
      {/*<div className="circle_large"></div>
        <div className="circle_medium"></div>
    <div className="circle_small"></div>*/}
      <div className={classes.root + " gradientBG"}>
        <p className={classes.title + " title_animate"}>
          TxtPolls<span className="beta"> Beta</span>
        </p>
        <p className={classes.subtitle + " subtitle_animate"}>
          Create text based polls that can be shared anywhere!
        </p>
        <Button
          variant="contained"
          component={Link}
          to="/faq"
          className="home_btn"
        >
          Learn More
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(HomeDisplay);
