import React from "react";
import { connect } from "react-redux";
import { startLogin } from "./../actions/auth";
import { Helmet } from "react-helmet";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import { withStyles } from "@material-ui/core/styles";

const Styles = {
  root: {
    display: "flex",
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100% !important",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "50px 30px",
    alignItems: "center",
  },
  gbtn: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: 40,
    border: 0,
    color: "white",
    width: "100%",
    margin: "30px 0px",
    height: 28,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  tbtn: {
    background: "linear-gradient(to right, #56ccf2, #2f80ed);",
    borderRadius: 40,
    border: 0,
    width: "100%",
    color: "white",
    margin: "30px 0px",
    height: 28,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(105, 195, 255, 0.49)",
  },

  fbtn: {
    background: "linear-gradient(to right, #0575e6, #021b79)",
    borderRadius: 40,
    width: "100%",
    border: 0,
    color: "white",
    margin: "30px 0px",
    height: 28,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(105, 140, 255, 0.5)",
  },
  banner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    background:
      " linear-gradient(270deg, #fe6b8b, #ff8e53, #fe6b8b, #ff8e53, #fe6b8b)",
    backgroundSize: "1000% 1000%",
    animation: "ccbtnAnim 19s ease infinite",
  },
  heading: {
    fontWeight: 900,
    textAlign: "center",
    fontSize: "4rem",
    color: "#fff",
    // background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    // "-webkit-background-clip": "text",
    // "-webkit-text-fill-color": "transparent",
  },
  headingalt: {
    fontWeight: 900,
    textAlign: "center",
    fontSize: "4rem",
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  card: {
    width: "100%",
    padding: "0 !important",
  },
  "@media (min-width: 1024px)": {
    gbtn: {
      width: 300,
    },
    fbtn: {
      width: 300,
    },
    tbtn: {
      width: 300,
    },
  },
};

class Login extends React.Component {
  state = {
    error: {
      e: undefined,
      msg: undefined,
    },
    dialogOpen: false,
  };

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({
        error: {
          e: this.props.location.state.error,
          msg: "🚫 You need to be logged in to do that.",
        },
      });
    }
  }

  componentWillMount() {
    this.setState({ dialogOpen: false });
  }

  handleClose = () => {
    this.setState({
      error: {
        e: false,
        msg: "",
      },
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className="container">
          <Helmet>
            <title>Login</title>
            <meta name="theme-color" content="#fff" />
          </Helmet>
          {
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={!!this.state.error.e}
              ContentProps={{
                "aria-describedby": "message-id",
              }}
              message={
                <span id="message-id" className="AddOption_errorMessage">
                  {this.state.error.msg}
                </span>
              }
              autoHideDuration={5000}
              onClose={this.handleClose}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={this.handleClose}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
            ></Snackbar>
          }
          <Dialog
            open={this.state.dialogOpen}
            disableBackdropClick
            disableEscapeKeyDown
          >
            <DialogTitle>Logging In</DialogTitle>
            <DialogContent>
              <DialogActions>
                <CircularProgress size={40} color="primary" />
                <DialogContentText>Please Wait</DialogContentText>
              </DialogActions>
            </DialogContent>
          </Dialog>
          <Card className={classes.card}>
            <div className="columns">
              <div className="column is-6  is-hidden-touch">
                <div className={classes.banner}>
                  <h1 className={classes.heading}>Login To TxtPolls</h1>
                </div>
              </div>
              <div className="column is-6">
                <div className={classes.btnContainer}>
                  <h1 className={classes.headingalt + " is-hidden-desktop"}>
                    Login To TxtPolls
                  </h1>
                  <Button
                    variant="contained"
                    size="large"
                    className={classes.fbtn}
                    onClick={() => {
                      this.setState({ dialogOpen: true });
                      this.props
                        .dispatch(startLogin("f"))
                        .then()
                        .catch((e) => {
                          this.setState({
                            error: {
                              e: true,
                              msg: e.message,
                            },
                            dialogOpen: false,
                          });
                        });
                      //props.history.push("/dashboard");
                    }}
                  >
                    Login With Facebook
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className={classes.gbtn}
                    onClick={() => {
                      this.setState({ dialogOpen: true });
                      this.props
                        .dispatch(startLogin("g"))
                        .then()
                        .catch((e) => {
                          this.setState({
                            error: {
                              e: true,
                              msg: e.message,
                            },
                            dialogOpen: false,
                          });
                        });
                      //props.history.push("/dashboard");
                    }}
                  >
                    Login With Google
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className={classes.tbtn}
                    onClick={() => {
                      this.setState({ dialogOpen: true });
                      this.props
                        .dispatch(startLogin("t"))
                        .then()
                        .catch((e) => {
                          this.setState({
                            error: {
                              e: true,
                              msg: e.message,
                            },
                            dialogOpen: false,
                          });
                        });
                    }}
                  >
                    Login With Twitter
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

export default withStyles(Styles)(connect()(Login));
