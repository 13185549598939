import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import PollForm from './PollForm';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { changeFirst } from '../actions/auth';
import { startAddPoll } from '../actions/poll';
import HomeDisplay from './HomeDisplay';
import Header from '../components/Header';
import Footer from './Footer';



class Home extends React.Component {

    state = {
        message: ""
    }

    handleMessageClosed = () => {
        this.setState({message: ""});
        this.props.location.state.error = false;
    }

    componentDidMount() {
        const locState = this.props.location.state;
        if (locState && locState.error) {
            this.setState({
                message: `Welcome, ${this.props.username} 👋`
            });
        }
    }

    render () {
        return (
        <div>
            <Helmet>
            <title>TxtPolls, Create free text based polls to share anywhere.</title>
                <meta name="theme-color" content="#0072ff"/>
            </Helmet>
            <HomeDisplay />
            <Header />
            <Snackbar 
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                    }}
                    open={!!this.state.message}
                    ContentProps={{
                        'aria-describedby': 'message-greet',
                    }}
                    message={<span id="message-greet" className="message-greet">{this.state.message}</span>}
                    autoHideDuration={5000}
                    onClose={this.handleMessageClosed}
                    action={[
                        <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={this.handleMessageClosed}
                        >
                        <CloseIcon />
                        </IconButton>,
                    ]}></Snackbar>
            <div className="container">
                <div className="home_intro_text_container">
                    <img src="/img/poll.svg" style={{height: 200}}/>
                    <h1 className="poll-h2">Create your Poll</h1>
                    <p>It's so simple, you could do it with your eyes shut!</p>
                </div>
                <PollForm auth={this.props.isAuthenticated} onSubmit={(poll) => {
                    this.props.dispatch(startAddPoll(poll));
                        //history.push("/details");
                }}/>
            </div>
            <Footer />
        </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: !!state.auth.uid,
        username: state.auth.displayName
    };
};

export default connect(mapStateToProps)(Home);