import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import {  withStyles } from '@material-ui/core';
import  Delete  from '@material-ui/icons/Delete';

const styles = {
    listText: {
        fontSize: "2rem"
    }
};

const Option = (props) => (  
    <ListItem>
        <ListItemText
        disableTypography={true}
        >
        <p className={props.classes.listText}
        >{props.count}. {props.data}</p>
        </ListItemText>
        <ListItemSecondaryAction>
            <IconButton onClick={(e) => {
                     props.removeOne(props.data);
                 }} aria-label="Delete">
            <Delete />
            </IconButton>
        </ListItemSecondaryAction>
    </ListItem>
);

// //<p>{props.count}. {props.data} </p>
// <button 
// onClick={(e) => {
//     props.removeOne(props.data);
// }}>
// Remove
// </button>

export default withStyles(styles)(Option);