import React from 'react';
import { withStyles } from '@material-ui/core/styles';


const styles = () => ({
    root: {
        color: "white",
        width: "100vw",
        background: "linear-gradient(to right, #0072ff, #00c6ff)",
        boxShadow: "inset 0 8px 9px 2px rgba(9, 124, 185, 0.43)",
        paddingTop: 40,
        paddingLeft: 16
    },
    footer_h1: {
        fontWeight: 800,
    },
    footer_p: {
        fontSize: "2rem"
    }
});

const Footer = props => {
    const {classes} = props;
   return ( 
        <div className={classes.root}>
            <div className="container">
                <div className="columns">
                    <div className="column is-6">
                        <h1 className={classes.footer_h1}>TxtPolls</h1>
                        <p className={classes.footer_p}>Made with <span style={{color: "#e74c3c"}}>&hearts;</span> by <b>Technity</b></p>
                    </div>
                    <div className="column is-6">
                    
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles)(Footer);


