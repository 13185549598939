import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { CSSTransition, TransitionGroup } from 'react-transition-group';


const styles = {
    addBtn: {
        background: "#00c6ff",
        background:" -webkit-linear-gradient(to right, #0072ff, #00c6ff)",
        background:" linear-gradient(to right, #0072ff, #00c6ff)", 
        borderRadius: 3,
        border: 0,
        margin: "8px -4px",
        color: 'white',
        height: 28,
        padding: '0 30px',
        boxShadow: '0 3px 9px 2px rgba(11, 169, 252, 0.43)',
        "&:hover": {
            boxShadow: "0 3px 14px 2px rgba(11, 169, 252, 0.43)"
        }
    },
    removeAll: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        margin: "8px -4px",
        height: 28,
        padding: '0 30px',
        boxShadow: '0 3px 9px 2px rgba(255, 105, 135, .3)',
        "&:hover": {
            boxShadow: "0 3px 14px 2px rgba(255, 105, 135, .3)"
        }
    },
    "@media (min-width: 1024px)": {
        addBtn: {
            width: 180,
        },
        removeAll: {
            width: 180
        },
        pollBtn: {
            width: "auto !important"
        }
    }
}

class AddOption extends React.Component {
    state = {
        error: undefined
    };
    
    handleAddOption = (e) => {
        e.preventDefault();
        const option = e.target.elements.option.value.trim();
        const error = this.props.addOption(option);
        if (!error) {
            e.target.elements.option.value = "";
        }
        this.setState(() => ({error}));
    }
    handleClose = () => {
        this.setState({error: undefined});
    }

    render() {
        return (
            
            <form  onSubmit={this.handleAddOption}>
                { <Snackbar 
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={!!this.state.error}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                      }}
                    message={<span id="message-id" className="AddOption_errorMessage">{this.state.error}</span>}
                    autoHideDuration={5000}
                    onClose={this.handleClose}
                    action={[
                        <IconButton
                          key="close"
                          aria-label="Close"
                          color="inherit"
                          onClick={this.handleClose}
                        >
                          <CloseIcon />
                        </IconButton>,
                      ]}


                >
                </Snackbar>}
                <div >
                <TextField
                    label="Your Option"
                    name="option"
                    id="outlined-poll-option"
                    className={"poll_input"}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    /> 
                <div className="columns is-mobile">
                      <div className={this.props.classes.pollBtn + " column is-6 is-narrow"}>
                        <Button fullWidth name="sbtn" type="submit" className={this.props.classes.addBtn}>Add</Button>
                    </div>
                      <div className={this.props.classes.pollBtn + " column is-6 is-narrow"}>
                    <TransitionGroup className="remove_btn_anim">
                      { this.props.options.length > 0 &&
                        <CSSTransition
                                key={"remove_all_btn"}
                                timeout={400}
                                classNames="fade_page"
                                >
                        <Button 
                        fullWidth
                         className={this.props.classes.removeAll}
                         onClick={this.props.removeAll}>
                         Remove All
                     </Button>
                     </CSSTransition>
                    }
                    </TransitionGroup>
                    </div>
                </div>
                </div>
            </form>
        ); 
    }
}

export default withStyles(styles)(AddOption);