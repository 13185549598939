import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import createHistory from "history/createBrowserHistory";
import Home from "../components/Home";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Login from "./../components/Login";
import VoteOption from "./../components/VoteOption";
import Details from "../components/Details";
import ResultPage from "../components/ResultPage";
import FourOFour from "../components/FourOFour";
import Dashboard from "../components/Dashboard";
import About from "../components/About";
import Privacy from "../components/Privacy";
import Donate from "../components/Donate";
import Faq from "../components/Faq";

export const history = createHistory();
history.listen(() => {
  window.scrollTo(0, 0);
});

//<PrivateRoute path="/edit/:id" component={EditExpense}/>
// <Route component={FourOFour}/>
const AppRouter = () => (
  <Router onUpdate={() => {}} history={history}>
    <div>
      <Route
        render={({ location }) => (
          <Switch location={location}>
            <Route path="/" exact={true} component={Home} />
            <PublicRoute path="/login" component={Login} />
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <Route path="/vote/:uid/:pollid/:opt" component={VoteOption} />
            <Route
              path="/result/:uid/:pollid"
              exact={true}
              component={ResultPage}
            />
            <Route path="/details/:uid/:pollid" component={Details} />
            <Route path="/about" component={About} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/support" component={Donate} />
            <Route path="/faq" component={Faq} />
            <Route component={FourOFour} />
          </Switch>
        )}
      />
    </div>
  </Router>
);

export default AppRouter;
