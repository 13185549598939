import React from 'react';
import { connect } from 'react-redux';
import { startGetResults, setResultPoll } from '../actions/poll';
import Results from './Results';
import Header from '../components/Header';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import {Helmet} from "react-helmet";


const styles = {
    progress: {
        color: "#FFF"
    },
    "homeBtn": {
        background: "#FE6B8B",
        background:" -webkit-linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', 
        borderRadius: "40px",
        border: 0,
        margin: "30px 10px",
        color: 'white',
        height: 28,
        padding: '0 30px',
        boxShadow: '0 3px 9px 2px rgba(255, 105, 135, .3)',
        "&:hover": {
            boxShadow: '0 3px 15px 2px rgba(255, 105, 135, .3)',
        }
    },
    "resultBtn":{
        background: "#f9d423",
        background:" -webkit-linear-gradient(to right, #00c3ff, #ffff1c)",
        background: 'linear-gradient(to right, #00c3ff, #ffff1c)', 
        borderRadius: "40px",
        border: 0,
        margin: "30px 30px",
        color: 'white',
        height: 28,
        padding: '0 30px',
        boxShadow: '0 3px 9px 2px rgba(11, 252, 30, 0.44)',
        "&:hover": {
            boxShadow: '0 3px 15px 2px rgba(11, 252, 30, 0.44)',
        }
    }
};

class ResultPage extends React.Component {
    state = {
        loaded: false
    }

    constructor(props) {
        super(props);
        this.params = props.match.params;
    }
    
    componentDidMount() {
        document.body.style = "background: linear-gradient(-20deg, #b721ff 0%, #21d4fd 100%);";
        this.props.dispatch(startGetResults(this.params.uid, this.params.pollid)).then((e) => {
            this.setState({loaded: true});
        }); 
    }

    componentWillUnmount() {
        document.body.style = "";
        this.props.dispatch(setResultPoll({}));
    }


    params = ""

    render() {
        const hasPollFetched = !!this.props.poll.title;
        return (
            <div className="mainPollPage">
                <Helmet>
                    <title>Results: {hasPollFetched ? this.props.poll.title : ""}</title>
                    <meta name="theme-color" content="#21d4fd"/>
                    <meta name="twitter:title" content={"Results for " + hasPollFetched ? this.props.poll.title : ""}/>
                    <meta name="og:title" content={"Results for " + hasPollFetched ? this.props.poll.title : ""}/>
                </Helmet>
                <div className="container">
                    <h1 className="pollResult">Results</h1>
                    {(!hasPollFetched && !this.state.loaded) && <div className="PollLoading">
                        <CircularProgress className={this.props.classes.progress} size={100} thickness={7}/>
                    </div>}
                    {(!hasPollFetched && this.state.loaded) && <ResultError />}
                    {(hasPollFetched && this.state.loaded) && <Results poll={this.props.poll}/>}
                    {(this.state.loaded) && <div className="container">
                        <div className="columns is-mobile btnContainer">
                            <div className="column is-6 vColumn">
                                <Button to="/" component={Link} className={this.props.classes.homeBtn}>GO HOME</Button>
                            </div>
                            {!(!hasPollFetched && this.state.loaded) && <div className="column is-6 vColumn">
                                <Button to={`/details/${this.params.uid}/${this.params.pollid}`} component={Link} className={this.props.classes.resultBtn}>View Poll Details</Button>
                            </div>}
                        </div>
                    </div>}
                </div>
                
            </div>
        );
    }
}

const ResultError = () => (
    <div className="container">
        <Card className="voteCard">
            <CardContent >
                <ErrorIcon className="voteError scale-up-center"/>
                <h1 className="voteSubtitle">Oh! Snap! There was an error.</h1>
                <h3 className="voteOption">The poll probably doesn't exist or you may have entered the wrong url.</h3>
            </CardContent>
        </Card>
    </div>
);

const mapStateToProps = (state) => {
    return {
        poll: state.poll
    };
};

export default withStyles(styles)(connect(mapStateToProps)(ResultPage));