import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    card: {
      margin: 20
    },
    cont: {
      width: "100%"
    },
    media: {
      height: 140,
      width:140,
      borderRadius: "50%"
    },
    imgHolder: {
      display: "flex",
      justifyContent: "center",
      padding: 40
    }
  };

const CoFounder = (props) => {
    const {classes} = props;
    return (
    <div>
    <Card className={classes.card}>
      <CardActionArea className={classes.cont}>
      <div className={classes.imgHolder}>
          <CardMedia
            className={classes.media}
            image={props.imgUrl}
            title={props.name}
          />
        </div>
        <CardContent>
        <div className="about__card__head">
          <h1 className="about__card__name" gutterBottom variant="h5" component="h2">
            {props.name}
          </h1>
          <p className="about__card__sub">Co Founder</p>
          <p className="about__card__sub">{props.college}</p>
        </div>
          <h2 className="about__card__desc">
            <b>{props.description}</b>
          </h2>
        </CardContent>
      </CardActionArea>
      <CardActions className="about__actions">
        {props.twitter && <IconButton disableRipple={true} className="about__icon" href={props.twitter} target="_blank" >
          <Icon className="fab fa-twitter-square about__twitter"/>
        </IconButton>}
        {props.insta && <IconButton disableRipple={true} className="about__icon" href={props.insta} target="_blank" >
          <Icon className="fab fa-instagram about__insta"/>
        </IconButton>}
        {props.facebook && <IconButton disableRipple={true} className="about__icon" href={props.facebook} target="_blank" >
          <Icon className="fab fa-facebook-square about__facebook"/>
        </IconButton>}
        {props.snap && <IconButton disableRipple={true} className="about__icon" href={props.snap} target="_blank" >
          <Icon className="fab fa-snapchat-square about__snap"/>
        </IconButton>}
        {props.gplus && <IconButton disableRipple={true} className="about__icon" href={props.gplus} target="_blank" >
          <Icon className="fab fa-google-plus-square about__gplus"/>
        </IconButton>}
        {props.github && <IconButton disableRipple={true} className="about__icon" href={props.github} target="_blank" >
          <Icon className="fab fa-github-square about__github"/>
        </IconButton>}
        {props.spotify && <IconButton disableRipple={true} className="about__icon" href={props.spotify} target="_blank" >
          <Icon className="fab fa-spotify about__spotify"/>
        </IconButton>}
        {props.steam && <IconButton disableRipple={true} className="about__icon" href={props.steam} target="_blank" >
          <Icon className="fab fa-steam-square about__steam"/>
        </IconButton>}
        {props.dribbble && <IconButton disableRipple={true} className="about__icon" href={props.dribbble} target="_blank" >
          <Icon className="fab fa-dribbble-square about__dribbble"/>
        </IconButton>}
      </CardActions>
    </Card>
    </div>
)
};

export default withStyles(styles)(CoFounder);