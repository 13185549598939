import React from "react";
import Option from '../components/Option';
import Button from '@material-ui/core/Button';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';


const styles = {
    
}

const OptionsList = (props) => {
        const {classes} = props;
        const opts = props.options;
        return (
            <div>
                {/*<div>
                    <h3 className="poll-h2">Your Options</h3>
                   
                </div>*/}
               <TransitionGroup className="add_option_txt_anim">
                    {props.options.length === 0 && 
                        <CSSTransition
                                key={"add_option_txt"}
                                timeout={400}
                                classNames="fade_page"
                                >
                        <p>Add an Option to get Started!</p>
                        </CSSTransition>
                    }
               </TransitionGroup> 
                    <List>
                        <TransitionGroup className="add_option_anim">
                        {opts.map((opt, index) => 
                            <CSSTransition
                                key={index}
                                timeout={400}
                                classNames="fade_page"
                                >
                                    <Option 
                                        key={index} 
                                        data={opt}
                                        count={index+1}
                                        removeOne={props.removeOne}
                                    />
                                </CSSTransition>
                            )}
                            </TransitionGroup>
                    </List>
                    
                
            </div>
        );  
};

export default withStyles(styles)(OptionsList);