import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistCombineReducers, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import auth from '../reducers/auth';
import poll from '../reducers/poll';
import selectedPoll from '../reducers/selectedPoll';
import userPolls from '../reducers/dashboard';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
    key: 'root',
    blacklist: ['selectedPoll', 'poll', 'userPolls'],
    storage
};

const persistedReducer = persistCombineReducers(persistConfig, {auth,
    poll,
    selectedPoll, 
    userPolls});

export default () => {
    let store = createStore(
        // combineReducers({
        //     auth,
        //     poll,
        //     selectedPoll, 
        //     userPolls

        // }), 
        persistedReducer, 
        composeEnhancers(applyMiddleware(thunk))
    );
    let persistor = persistStore(store);
    return {store, persistor};
};

