import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import LockIcon from "@material-ui/icons/Lock";
import FaqIcon from "@material-ui/icons/ContactSupport";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import AboutIcon from "@material-ui/icons/Info";
import DonateIcon from "@material-ui/icons/Favorite";
import HomeIcon from "@material-ui/icons/Home";
import LoginIcon from "@material-ui/icons/Person";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import DashboardIcon from "@material-ui/icons/Dashboard";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";

import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { history } from "../routers/AppRouter";
import Button from "@material-ui/core/Button";
import { startLogout } from "../actions/auth";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  links: {
    "&:hover": {
      color: "black",
    },
  },
  logo: {
    fontWeight: 900,
    flexGrow: 1,
    fontSize: "2.4rem",
  },
  loginButton: {
    fontSize: "1.3rem",
    fontWeight: 900,
  },
  bigAvatar: {
    margin: "0 10px",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  hamburger: {
    fill: "white",
  },
  list: {
    width: "250px",
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  appBarContainer: {
    top: 0,
    position: "sticky",
    zIndex: 1100,
  },
  appBar: {
    boxShadow: "0 4.5px 10px 1px rgba(37, 167, 255, 0.49)",
  },
  card: {
    borderRadius: 0,
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    boxShadow: "0 3px 15px 2px #cbcbcb",
  },
});

// TODO: REMOVE IF EVERYTHING WORKS FINE
// {false ?
//     <div onClick={() => {
//         this.props.dispatch(startLogout());
//         history.push("/login");
//     }}>
//     <SuccessJSX user={this.props.user} classes={classes}/>
//     </div>
//     :  <Button className={classes.loginButton} component={Link} to="/login" color="secondary">LOGIN</Button>}

class subHeader extends React.Component {
  state = {
    DrawerOpen: false,
    dialogOpen: false,
    btnLoading: false,
    message: false,
    notification: false,
  };

  loadNotification = true;

  handleLogOut = () => {
    this.setState({
      btnLoading: true,
    });
    this.props.dispatch(startLogout());
    this.setState({
      dialogOpen: false,
      message: true,
    });
  };

  handleMessageClosed = () => {
    this.setState({
      message: false,
    });
  };

  onHomeClick = () => {
    const location = history.location.pathname;
    if (location === "/") {
      this.setState({
        //could've used this.toggleDrawer(false), but didn't work. Strange, ikr - future tkspree!
        DrawerOpen: false,
      });
    } else {
      history.push("/");
    }
  };
  onDashBoardClick = () => {
    const location = history.location.pathname;
    if (location === "/dashboard") {
      this.setState({
        //could've used this.toggleDrawer(false), but didn't work. Strange, ikr - future tkspree!
        DrawerOpen: false,
      });
    } else {
      history.push("/dashboard");
    }
  };

  openDialog = () => {
    this.setState({
      dialogOpen: true,
      DrawerOpen: false,
    });
  };

  handleCloseDialog = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  toggleDrawer = (DrawerOpen) => () => {
    this.setState({ DrawerOpen });
  };

  //     <ListItem className={classes.links} button onClick={this.onHomeClick} component={Link} to={"/"}>
  //     <ListItemIcon>
  //         <HomeIcon />
  //     </ListItemIcon>
  // Home
  // </ListItem>
  // <ListItem className={classes.links} button onClick={this.onDashBoardClick} component={Link} to={"/dashboard"}>
  //     <ListItemIcon>
  //         <DashboardIcon />
  //     </ListItemIcon>
  // Dashboard
  render() {
    const { classes } = this.props;
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    return (
      <div
        className={
          classes.appBarContainer +
          " " +
          (this.props.transparent ? "transparent_appbar" : "")
        }
      >
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={!!this.state.message}
          ContentProps={{
            "aria-describedby": "message-logout",
          }}
          message={
            <span id="message-logout" className="message-l">
              Logged Out! See ya Later 👋
            </span>
          }
          autoHideDuration={5000}
          onClose={this.handleMessageClosed}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleMessageClosed}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        ></Snackbar>
        {this.props.payload && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={!!this.props.payload}
            ContentProps={{
              "aria-describedby": "message-notification",
            }}
            message={
              <span id="message-notification" className="message-l">
                {this.props.payload.title}
              </span>
            }
            action={[
              <Button
                color="primary"
                component={Link}
                to={this.props.payload.absolute}
              >
                VIEW RESULTS
              </Button>,
            ]}
          ></Snackbar>
        )}
        <Dialog open={this.state.dialogOpen} onClose={this.handleCloseMenu}>
          <DialogTitle>Are you sure you want to Log Out?</DialogTitle>
          <DialogContent>
            <DialogActions>
              {this.state.btnLoading && (
                <CircularProgress size={24} color="primary" />
              )}
              <Button
                disabled={this.state.btnLoading}
                onClick={this.handleCloseDialog}
              >
                CANCEL
              </Button>
              <Button onClick={this.handleLogOut} color="primary">
                LOG OUT
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <SwipeableDrawer
          open={this.state.DrawerOpen}
          disableDiscovery={iOS}
          onClose={this.toggleDrawer(false)}
          onOpen={this.toggleDrawer(true)}
        >
          <div className={classes.list}>
            {this.props.isAuthenticated ? (
              <div>
                <Card
                  className={classes.card}
                  style={{
                    background:
                      "linear-gradient(45deg, " +
                      this.props.user.palette.lightVibrant +
                      " 30%, " +
                      this.props.user.palette.vibrant +
                      " 90%)",
                  }}
                >
                  <CardHeader
                    titleTypographyProps={{ color: "secondary" }}
                    title={this.props.user.displayName}
                    avatar={
                      <Avatar
                        alt={this.props.user.displayName}
                        className={this.props.classes.bigAvatar}
                      >
                        <img
                          id="avtr"
                          alt={this.props.user.name ?? "user"}
                          src={this.props.user.photoURL}
                        />
                      </Avatar>
                    }
                  />
                </Card>
                <List>
                  <ListItem
                    className={classes.links}
                    button
                    onClick={this.onHomeClick}
                  >
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    Home
                  </ListItem>
                  <ListItem
                    className={classes.links}
                    button
                    onClick={this.onDashBoardClick}
                  >
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    Dashboard
                  </ListItem>
                  <ListItem button onClick={this.openDialog}>
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    Logout
                  </ListItem>
                </List>
              </div>
            ) : (
              <List>
                <ListItem
                  className={classes.links}
                  button
                  onClick={this.onHomeClick}
                >
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  Home
                </ListItem>
                <ListItem
                  button
                  className={classes.links}
                  component={Link}
                  to="/login"
                >
                  <ListItemIcon>
                    <LoginIcon />
                  </ListItemIcon>
                  Login
                </ListItem>
              </List>
            )}

            <Divider />
            <List>
              <ListItem
                button
                className={classes.links}
                component={Link}
                to="/faq"
              >
                <ListItemIcon>
                  <FaqIcon />
                </ListItemIcon>
                FAQ
              </ListItem>
              <ListItem
                button
                className={classes.links}
                component={Link}
                to="/about"
              >
                <ListItemIcon>
                  <AboutIcon />
                </ListItemIcon>
                About
              </ListItem>
              <ListItem
                button
                className={classes.links}
                component={Link}
                to="/support"
              >
                <ListItemIcon>
                  <DonateIcon />
                </ListItemIcon>
                Support Us
              </ListItem>
              <ListItem
                button
                className={classes.links}
                component={Link}
                to="/privacy"
              >
                <ListItemIcon>
                  <LockIcon />
                </ListItemIcon>
                Privacy Policy
              </ListItem>
            </List>
          </div>
        </SwipeableDrawer>
        <div className={classes.root}>
          <AppBar
            position={this.props.transparent ? "static" : "sticky"}
            className={
              classes.appBar +
              " gradientBG appBarContainer " +
              (this.props.transparent ? "transparent_appbar" : "")
            }
          >
            <Toolbar>
              <IconButton
                onClick={() => {
                  this.setState({ DrawerOpen: true });
                }}
              >
                <MenuIcon className={classes.hamburger} />
              </IconButton>
              <Typography
                variant="title"
                color="secondary"
                className={classes.logo}
              >
                TxtPolls<span className="beta"> Beta</span>
              </Typography>
            </Toolbar>
          </AppBar>
        </div>
      </div>
    );
  }
}

// const SuccessJSX = (props) => {
//   return (
//     <div>
//       <div className={props.classes.sectionDesktop}>
//         <Button className={props.classes.loginButton} color="secondary">
//           {props.user.displayName}
//         </Button>

//         <Button className={props.classes.loginButton} color="secondary">
//           LOGOUT
//         </Button>
//       </div>
//       <div className={props.classes.sectionMobile}>
//         <Button className={props.classes.loginButton} color="secondary">
//           {props.user.displayName}
//         </Button>
//         <Avatar
//           alt={props.user.displayName}
//           src={props.user.photoURL}
//           className={props.classes.bigAvatar}
//         />
//       </div>
//     </div>
//   );
// };

const mapStateToProps = (state) => {
  return {
    isAuthenticated: !!state.auth.uid,
    user: state.auth,
    payload: state.auth.payload ? state.auth.payload.notification : undefined,
  };
};

const Header = connect(mapStateToProps)(subHeader);

export default withStyles(styles)(Header);
