import React from 'react';  

class Ad extends React.Component {

    componentDidMount() {
        //(window.adsbygoogle = window.adsbygoogle || []).push({});
       // <ins className="adsbygoogle"
                // style={{display: "block"}}
                // data-ad-format="fluid"
                // data-ad-layout-key="-fb+5w+4e-db+86"
                // data-ad-client="ca-pub-6520305650848116"
                // data-ad-slot="5564778259"></ins>
    }
    render() {
        return (
            <div className="ad">
                <ins className="adsbygoogle"
                style={{display:"block", textAlign:"center"}}
                data-ad-layout="in-article"
                data-ad-format="fluid"
                data-ad-client="ca-pub-6520305650848116"
                data-ad-slot="5345038986"></ins>
            </div>
            
        );
    }
}

export default Ad;