import React from "react";
import {
  CSSTransition,
  TransitionGroup,
  Transition,
} from "react-transition-group";
import Tooltip from "@material-ui/core/Tooltip";

const widthDefault = {
  transition: `width 2s ease-in-out`,
  width: 0,
};

const transitionStyles = (width) => {
  return {
    entering: { width: 0 },
    entered: { width: width + "%" },
  };
};

class PollResult extends React.Component {
  state = {
    totalVotes: 0,
    done: false,
    open: [],
    load: false,
  };

  options = [];

  constructor(props) {
    super(props);
    this.options = props.options;
  }

  componentDidMount() {
    this.getPollValues();
  }

  getPollValues() {
    this.options.forEach((opt) => {
      this.setState((prevState) => ({
        totalVotes: prevState.totalVotes + opt.votes,
      }));
    });
    this.setState({
      done: true,
    });
  }

  render() {
    return (
      <div>
        <div style={{ padding: "0 18px", paddingTop: "36px" }}>
          <TransitionGroup className="poll-result">
            {this.options.length > 0 &&
              this.state.done &&
              this.options.map((opt, index) => {
                const percent =
                  this.state.totalVotes > 0
                    ? (opt.votes / this.state.totalVotes) * 100
                    : 0;
                return (
                  <CSSTransition
                    key={index}
                    timeout={400}
                    classNames="fade_page"
                    onEntered={() => {
                      this.setState({ load: true });
                    }}
                  >
                    <Tooltip
                      open={!!this.state.open[index]}
                      key={index}
                      onClose={() => {
                        this.setState((prevState) => {
                          let open = prevState.open;
                          open[index] = false;
                          return {
                            open,
                          };
                        });
                      }}
                      placement="top"
                      title={
                        opt.votes + (opt.votes == 1 ? " vote." : " votes.")
                      }
                    >
                      <div
                        onClick={() => {
                          this.setState((prevState) => {
                            let open = prevState.open;
                            open[index] = true;
                            return {
                              open,
                            };
                          });
                        }}
                        key={index}
                        className="pollSection"
                      >
                        <div className="columns is-mobile pollColumns">
                          <div className="column is-9 pollColumn">
                            <p>{opt.option}</p>
                          </div>
                          <div className="column is-3 pollColumn">
                            <p style={{ textAlign: "right", width: "100%" }}>
                              {parseFloat(
                                Math.round(percent * 100) / 100
                              ).toFixed(2)}
                              %
                            </p>
                          </div>
                        </div>
                        <Transition
                          in={
                            this.options.length > 0 &&
                            this.state.done &&
                            this.state.load
                          }
                          timeout={300}
                        >
                          {(state) => (
                            <div
                              className="pollValue"
                              style={{
                                ...widthDefault,
                                ...transitionStyles(percent)[state],
                              }}
                            ></div>
                          )}
                        </Transition>
                      </div>
                    </Tooltip>
                  </CSSTransition>
                );
              })}
          </TransitionGroup>
        </div>
      </div>
    );
  }
}

export default PollResult;
