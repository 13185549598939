const reducer = (state = {}, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        ...action.userInfo,
        isFirst: true,
        payload: undefined,
      };
    case "MESSAGE":
      return {
        ...state,
        payload: action.payload,
      };
    case "LOGOUT":
      return {};
    default:
      return state;
  }
};

export default reducer;
