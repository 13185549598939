import React from "react";
import Filter from "bad-words";
import { Link } from "react-router-dom";
import AddOption from "./AddOption";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import OptionsList from "./OptionsList";
import "bulma/css/bulma.css";
import Cookies from "universal-cookie";
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import { withStyles } from "@material-ui/core/styles";

const cookies = new Cookies();
const filter = new Filter();
filter.removeWords("hells", "hell", "gay");
filter.addWords([
  "chutiya",
  "gand",
  "gandu",
  "madarchod",
  "bhenchod",
  "jhattu",
  "jhat",
  "chut",
  "gaandu",
  "lund",
  "laude",
  "bc",
  "mc",
  "lavde",
  "kutte",
  "rand",
  "randi",
  "bhosdike",
  "bhosadike",
]); //Yeah, right I'm not proud of this either.

const styles = {
  submitBtn: {
    background: "#00c6ff",
    background: " -webkit-linear-gradient(to right, #0072ff, #00c6ff)",
    background: " linear-gradient(to right, #0072ff, #00c6ff)",
    borderRadius: 3,
    border: 0,
    width: "99.5%",
    color: "white",
    height: 48,
    marginLeft: "0px",
    padding: "0 30px",
    boxShadow: "0 3px 9px 2px rgba(11, 169, 252, 0.43)",
    "&:hover": {
      boxShadow: "0 3px 14px 2px rgba(11, 169, 252, 0.43)",
    },
  },
  stepper: {
    background: "#00c6ff",
    background: " -webkit-linear-gradient(to right, #0072ff, #00c6ff)",
    background: " linear-gradient(to right, #0072ff, #00c6ff)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
    margin: "20px 0px",
    boxShadow: "0 3px 9px 2px rgba(11, 169, 252, 0.43)",
    "&:hover": {
      boxShadow: "0 3px 14px 2px rgba(11, 169, 252, 0.43)",
    },
  },
  "@media (min-width: 1024px)": {
    submitBtn: {
      width: 376.5,
    },
    stepper: {
      margin: "0px 16px",
    },
  },
};

class PollForm extends React.Component {
  state = {
    title: "",
    activeStep: 0,
    description: "",
    options: [],
    error: undefined,
    removed: undefined,
    removedAll: undefined,
    btnLoading: false,
    dialogOpen: false,
  };

  componentDidMount() {
    let prevState = cookies.get("log_poll");
    if (prevState) this.setState(prevState);
  }

  handleCaution = () => {
    this.setState({
      btnLoading: true,
    });
  };

  openDialog = () => {
    const { title, options } = this.state;
    if (title && options.length >= 2) {
      cookies.set("log_poll", this.state, { path: "/" });
      this.setState({
        dialogOpen: true,
      });
    } else {
      if (!title) this.setState({ error: "Title Must Not be Empty." });
      else if (options.length < 2)
        this.setState({ error: "You must add at least 2 options" });
    }
  };

  handleCloseDialog = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  onSubmit = () => {
    cookies.set("log_poll", "", { path: "/" });
    const { title, options } = this.state;
    if (title && options.length >= 2) {
      const poll = {
        title: filter.clean(this.state.title),
        hasEnded: false,
        description: this.state.description,
        options: this.state.options,
        voters: [],
        timeAdded: Date.now(),
      };
      this.props.onSubmit(poll);
    } else {
      if (!title) this.setState({ error: "🚫 Title Must Not be Empty." });
      else if (options.length < 2)
        this.setState({ error: "🚫 You must add at least 2 options" });
    }
  };

  handleNext = () => {
    const { title } = this.state;
    if (!title) this.setState({ error: "🚫 Title Must Not be Empty." });
    else {
      this.setState((state) => ({
        activeStep: state.activeStep + 1,
      }));
    }
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  handleClose = () => {
    this.setState({ error: undefined });
  };
  handleRemoveClose = () => {
    this.setState({ removed: undefined });
  };
  handleRemoveAllClose = () => {
    this.setState({ removedAll: undefined });
  };

  onTitleValueChanged = (e) => {
    const title = e.target.value;
    this.setState(() => ({ title }));
  };

  removeOne = (option) => {
    this.setState((prevState) => ({
      removed: option,
      options: prevState.options.filter((o) => {
        return o.option !== option;
      }),
    }));
  };

  undoRemove = () => {
    this.addOption(this.state.removed);
    this.setState({ removed: undefined });
  };

  undoRemoveAll = () => {
    const removed = this.state.removedAll;
    removed.forEach((element) => {
      this.addOption(element.option);
    });
    this.setState({ removedAll: undefined });
  };

  removeAll = () => {
    this.setState((prevState) => ({
      removedAll: prevState.options,
      options: [],
    }));
  };

  getOnlyOptions = () => {
    return this.state.options.length !== 0
      ? this.state.options.map((opt) => opt.option)
      : [];
  };

  hasDuplicates = (option) => {
    const opts = this.state.options;
    let found = false;
    for (let i = 0; i < opts.length; i++) {
      if (opts[i].option === option) {
        found = true;
        break;
      }
    }
    return found;
  };

  addOption = (option) => {
    if (!option) {
      return "🚫 Option Cannot be empty";
    } else if (this.state.options.length >= 25) {
      return "🚫 You can't add more options. Maximum limit is 25.";
    } else if (this.hasDuplicates(option)) {
      return "🚫 You cannot add duplicate options.";
    } else {
      const o = {
        option: filter.clean(option),
        votes: 0,
      };
      this.setState((prevState) => ({
        options: prevState.options.concat(o),
      }));
    }
    if (filter.isProfane(option)) {
      return "Ah! We don't like Profane Language 🤐!";
    }
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.dialogOpen} onClose={this.handleCloseMenu}>
          <DialogTitle>You are adding this poll anonymously.</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Anonymous polls will expire in 15 days and you won't be able to
              vote or view the results of this poll. Login to TxtPolls if you
              want much more control over your polls. Logging in hardly takes
              any time.
            </DialogContentText>
            <DialogActions>
              {this.state.btnLoading && (
                <CircularProgress size={24} color="primary" />
              )}
              <Button
                onClick={this.handleCaution}
                color="primary"
                component={Link}
                to="/login"
              >
                LOGIN
              </Button>
              <Button
                disabled={this.state.btnLoading}
                onClick={this.onSubmit}
                color="primary"
              >
                SUBMIT ANYWAY
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={!!this.state.removedAll}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={
            <span id="message-id" className="AddOption_errorMessage">
              ⚠️ All Options Removed
            </span>
          }
          autoHideDuration={5000}
          onClose={this.handleRemoveAllClose}
          action={[
            <Button
              key="undo"
              color="secondary"
              size="small"
              onClick={this.undoRemoveAll}
            >
              UNDO
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleRemoveAllClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        ></Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={!!this.state.removed}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={
            <span id="message-id" className="AddOption_errorMessage">
              ⚠️ Option Removed
            </span>
          }
          autoHideDuration={5000}
          onClose={this.handleRemoveClose}
          action={[
            <Button
              key="undo"
              color="secondary"
              size="small"
              onClick={this.undoRemove}
            >
              UNDO
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleRemoveClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        ></Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={!!this.state.error}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={
            <span id="message-id" className="AddOption_errorMessage">
              {this.state.error}
            </span>
          }
          autoHideDuration={5000}
          onClose={this.handleClose}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        ></Snackbar>

        <div className="container mainPollContainer">
          <div>
            <Paper elevation={1}>
              <Stepper
                activeStep={this.state.activeStep}
                orientation="vertical"
              >
                <Step>
                  <StepLabel
                    optional={
                      this.state.activeStep == 1 && <p>{this.state.title}</p>
                    }
                  >
                    <p className="poll-h2">Title</p>
                  </StepLabel>
                  <StepContent>
                    <p>Give your poll a title.</p>
                    <TextField
                      id="outlined-poll-title"
                      label="Poll Title"
                      value={this.state.title}
                      name="title"
                      className="poll_input"
                      onChange={this.onTitleValueChanged}
                      margin="normal"
                      fullWidth
                      variant="outlined"
                    />
                    <Button
                      variant="contained"
                      className={this.props.classes.stepper}
                      style={{ margin: "10px 8px" }}
                      color="primary"
                      onClick={this.handleNext}
                    >
                      NEXT
                    </Button>
                    <br />
                  </StepContent>
                </Step>
                <Step>
                  <StepLabel className="poll-h2">Add Options</StepLabel>
                  <StepContent>
                    <OptionsList
                      options={this.getOnlyOptions()}
                      removeOne={this.removeOne}
                    />
                    <br></br>
                    <AddOption
                      options={this.getOnlyOptions()}
                      removeAll={this.removeAll}
                      addOption={this.addOption}
                    />
                    <br></br>
                    <Button
                      fullWidth
                      className={this.props.classes.submitBtn}
                      onClick={
                        this.props.auth ? this.onSubmit : this.openDialog
                      }
                    >
                      Submit Poll
                    </Button>
                    <Button
                      variant="contained"
                      className={this.props.classes.stepper}
                      color="primary"
                      onClick={this.handleBack}
                    >
                      PREVIOUS
                    </Button>
                    <br />
                    <br />
                  </StepContent>
                </Step>
              </Stepper>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PollForm);
